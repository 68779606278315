import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-purewood-rev-ezug-special-display',
  templateUrl: './purewood-rev-ezug-special-display.component.html',
  styleUrls: ['./purewood-rev-ezug-special-display.component.css']
})
export class PurewoodRevEzugSpecialDisplayComponent {
  // Used to determine if the drawer depth is negative in the html component...
  infinityValue = Infinity;

  @Input() drawerDepth: number = Infinity;

  measurements = {
    hr: null,
    vt: null,
  };

  validateVT(value: number): boolean {
    return value >= 40 && value <= this.getDrawerDepth();
  }

  validateHR(value: number): boolean {
    return value >= 45;
  }

  isValid(): boolean {
    return this.validateVT(this.measurements.vt) &&
      this.validateHR(this.measurements.hr);
  }

  private getDrawerDepth(): number {
    if (this.drawerDepth < 0) return Infinity;
    return this.drawerDepth;
  }
}
