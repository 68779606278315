import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IDrawerOptions, SlideListOptionValue } from '../../../../wdcommon/IDrawer';
import { OptionProperty } from '../../../../wdcommon/IProduct';
import { sortOptions } from '../../../../wdcommon/SortedOptions';
import { TranslatingBase } from '../base-component/ComponentBase';
import { APIService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class NamingService extends TranslatingBase {

  constructor(
      private api: APIService,
      private translateService: TranslateService
  ) {
    super(translateService);
  }

  async prettyNameList(obj: Partial<IDrawerOptions>): Promise<{ key: string, value: string; }[]> {
    const prettyObj: { key: string, value: string; }[] = [];
    const addValue = (key: string, value: string) => prettyObj.push({ key, value });

    for (const [key, value] of Object.entries(obj).sort(sortOptions)) {
      if (value === null || value === undefined) {
        continue;
      }

      const pretty = this.getPrettyNameSync(key, value);
      addValue(pretty.key, pretty.value);
    }

    return prettyObj;
  }

  getPrettyNameSync(key: string, value: string[] | any): { key: string, value: string; } {
    if (!key || typeof key === 'undefined') {
      return;
    }

    const translatedKey = this.translate(`CARTDETAILS.${key}`);

    if (key === 'bestikindsats') {
      return {
        key: translatedKey,
        value: this.translate(`CARTVALUE.bestikindsats.${value.special ? 'special' : 'normal'}`, {
          ...value,
          depth: value[OptionProperty.depth],
          typeOfWood: this.translate(`TREESORTS.${value[OptionProperty.typeOfWood]}`),
          width: value[OptionProperty.width],
        })
      };
    }

    if (key === 'udsparing') {
      return {
        key: translatedKey,
        value: value.hasOwnProperty('ef') ? this.translate(`CARTVALUE.udsparing.${value.special ? 'special' : 'normal'}`, value) : this.translate(`CARTVALUE.rearudsparing`, value)

      };
    }

    if (key === 'greb') {
      return {
        key: translatedKey,
        value: value.toUpperCase()
      };
    }

    if (key === 'logo') {
      return {
        key: translatedKey,
        value: this.translate('CARTVALUE.logo.' + value.placement, value)
      };
    }

    if (typeof (value) === 'boolean') {
      return {
        key: translatedKey,
        value: value ? this.translate('UTILITY.YES') : this.translate('UTILITY.NO')
      };
    }

    if (typeof (value) === 'number') {
      return {
        key: translatedKey,
        value: `${value}mm`
      };
    }

    if (['rearEnglisherZug', 'reverseEnglisherZug'].includes(key)) {
      return {
        key: translatedKey,
        value: this.translate(`CARTVALUE.${key}.${value.special ? 'special' : 'normal'}`, value)
      };
    }

    if (key === 'reverseEnglisherZugSpecial') {
      return {
        key: translatedKey,
        value: this.translate(`CARTVALUE.${key}`, value)
      };
    }

    if (key === 'innenlade') {
      return {
        key: translatedKey,
        value: ''
      };
    }

    if (key === 'type') {
      return {
        key: translatedKey,
        value: this.translate(`DRAWER_NAMES.${value}`)
      };
    }

    if (key === OptionProperty.typeOfWood) {
      if (typeof value === 'string')
        return {
          key: translatedKey,
          value: this.translate(`TREESORTS.${value}`)
        };

      return {
        key: translatedKey,
        value: (value as String[]).map(v => this.translate(`TREESORTS.${v}`)).join('')
      };
    }

    if ([OptionProperty.drawerBase, OptionProperty.joint, OptionProperty.runnerMark, OptionProperty.woodQuality + ''].includes(key)) {
      return {
        key: translatedKey,
        value: this.translate(key.toUpperCase() + '.' + value)
      };
    }

    if (key === OptionProperty.surfaceTreatment) {
      return {
        key: translatedKey,
        value: this.translate(`SURFACES.${value}`)
      };
    }

    if (key === OptionProperty.shimsSelection) {
      return {
        key: translatedKey,
        value: value !== 'nej' ? value : this.translate('UTILITY.NO')
      };
    }

    if (key === OptionProperty.slideList) {
      const _value = value;

      if (_value === SlideListOptionValue.slideNo) {
        value = this.translate('UTILITY.NO');
      } else if (_value === SlideListOptionValue.slideYesPlusSix) {
        value = this.translate('foeringsListeJa6mm');
      } else {
        value = this.translate('UTILITY.YES');
      }

      return {
        key: translatedKey,
        value
      };
    }

    if (key === OptionProperty.runnerType) {
      return {
        key: translatedKey,
        value: this.translate(value),
      };
    }

    if (key === OptionProperty.thickness) {
      return {
        key: translatedKey,
        value: this.translate(`THICKNESS.${value}`)
      };
    }

    if (value && key) {
      console.warn('Name-value from old system', key, value);
    }
    return {
      key: translatedKey,
      value: value
    };
  }

}
