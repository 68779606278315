import { Component, Input } from '@angular/core';
import { ILogo } from '../../../../wdcommon/ICompany';

@Component({
  selector: 'app-logo-preview',
  templateUrl: './logo-preview.component.html',
  styleUrls: ['./logo-preview.component.css']
})
export class LogoPreviewComponent {
  @Input() logo: ILogo;
  @Input() imageMaxWidth: string = "100%";
  @Input() pdfDownloadable: boolean = true;

  public get isLogoPdf(): boolean {
    return this.logo.origFilename.endsWith(".pdf");
  }

  public downloadPdf(event: MouseEvent): void {
    if (!this.isLogoPdf) return;

    event.preventDefault();
    event.stopPropagation();

    const blob = this.b64toBlob(this.logo.fileBase64.split(',')[1], 'application/pdf');
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = this.logo.origFilename;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  private b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
