import { OptionProperty } from './IProduct';

const sortedOptionsIndex = [
  'type',
  OptionProperty.woodQuality,
  OptionProperty.typeOfWood,
  OptionProperty.joint,
  OptionProperty.surfaceTreatment,
  OptionProperty.surfaceTreatmentUnderside,
  OptionProperty.runnerDepth,
  OptionProperty.drawerOuterWidth,
  'skuffeBredde',
  'skuffeDybde',
  'skuffeHoejde',
  'frontBredde',
  'frontHoejde',
  OptionProperty.thickness,
  OptionProperty.runnerMark,
  OptionProperty.runnerType,
  OptionProperty.runnerLoad,
  OptionProperty.premountedCoupling,
  'udtraekLeveret',
  'udtraekDybdeJustering',
  OptionProperty.runnerPushToOpenSilent,
  OptionProperty.synchronisationBar,
  'reverseEnglisherZug',
  'reverseEnglisherZugSpecial',
  'rearEnglisherZug',
  OptionProperty.drawerBase,
  OptionProperty.couplingAlongside,
  'skuffe20mmFront',
  'straightLine',
  'boringFront',
  'boringCutleryTray',
  'bundSkruet',
  'boerstet',
  OptionProperty.bottomUnmounted,
  'greb',
  'udsparing',
  'bestikindsats',
  'logo',
  OptionProperty.shimsSelection,
  OptionProperty.slideList,
  'hulboring',
];

export function sortOptions(a: [string, any], b: [string, any]) {
  const aKey = a[0];
  const bKey = b[0];
  const aIndex = sortedOptionsIndex.indexOf(aKey);
  const bIndex = sortedOptionsIndex.indexOf(bKey);
  return aIndex - bIndex;
}
