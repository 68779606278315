<span class="logo-preview-container">
    <ng-container *ngIf="isLogoPdf; else imageTemplate">
        <!-- Display PDF logo -->
        <img [alt]="logo.origFilename" class="logo-list-image-height" src="../../../assets/images/pdf.png" style="max-height: 180px;">
    </ng-container>
    <ng-template #imageTemplate>
        <!-- Display actual image -->
        <img [alt]="logo.origFilename" class="logo-list-image-height" [src]="logo.fileBase64" [style.max-width]="imageMaxWidth">
    </ng-template>
    <span style="display: flex; flex-direction: column; justify-content: center;">
        {{ logo.origFilename }}
        <a *ngIf="pdfDownloadable && isLogoPdf" (click)="downloadPdf($event)" class="download-link">Download PDF</a>
    </span>
</span>