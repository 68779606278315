import { IDrawerOptions, ShimsOptionValue } from './IDrawer';
import { ExternalShopName } from './IExternalShop';
import { FrontCategory, FrontDesign, OptionProperty } from './IProduct';

export enum CartSubType {
  bestikskuffe,
  profil,
  afstandsliste,
  fronts,
  solidOrganiser,
  trashcanDrawer,
  sortissimoFastline,
  slacksDrawer,
  board,
  cupboard,
  baseUnit,
  wallUnit,
  careSet,
}

export const OrderManuallyAtNothegger = [CartSubType.profil, CartSubType.board, CartSubType.cupboard, CartSubType.baseUnit, CartSubType.wallUnit];

export interface ISiphonCuttingOptions {
  ab: number;
  bc: number;
  cd: number;
  ef: number;
  special: boolean;
  image: string;
}

export interface IRearUdsparingOptions {
  ab: number;
  bc: number;
  cd: number;
  de: number;
  fg: number;
  gh: number;
  special: boolean;
  image: string;
}

export interface ICareSetOptions {
  [OptionProperty.articleNumber]: string;
}

export interface ITraesorterOverfladeOptions {
  [OptionProperty.typeOfWood]: string | string[];
  [OptionProperty.surfaceTreatment]: string;
  [OptionProperty.surfaceTreatmentUnderside]: boolean;
}

export interface IShimsOptions {
  [OptionProperty.typeOfWood]: string;
  [OptionProperty.surfaceTreatment]: string;
  [OptionProperty.shimsSelection]: ShimsOptionValue;
}

export interface IProfileOptions {
  [OptionProperty.typeOfWood]: string;
  [OptionProperty.surfaceTreatment]: string;
  length: number;
  [OptionProperty.thickness]: number;
  [OptionProperty.height]: number;
}

export interface IOtherItemOptions {
  type: string;
  [OptionProperty.depth]: number;
  [OptionProperty.height]: number;
  [OptionProperty.width]: number;
  [OptionProperty.typeOfWood]: string;
  [OptionProperty.surfaceTreatment]: string;
}

export interface ICutleryTrayOptions extends IOtherItemOptions {
  special: boolean;
  bundLeveret: boolean;
}

export interface ISlacksDrawerOptions extends IOtherItemOptions {
  coupling: boolean;
}

export interface IBoardOptions {
  [OptionProperty.comment]?: string;
  [OptionProperty.height]: number;
  [OptionProperty.surfaceTreatment]?: string;
  [OptionProperty.veneer]: string;
  [OptionProperty.width]: number;
}

interface ICabinetCarcassBaseOptions extends IBoardOptions {
  [OptionProperty.depth]: number;
  [OptionProperty.shelves]: number;
  [OptionProperty.shelfHoles]: string;
  [OptionProperty.cornerJoin]: string;
}

export interface ICabinetCarcassOptions extends ICabinetCarcassBaseOptions {
  [OptionProperty.plinth]?: number;
}

export interface IWallUnitOptions extends ICabinetCarcassBaseOptions {
  [OptionProperty.suspensionFittings]: boolean;
}

export interface ILogoOptions {
  id: any;
  name: string;
  placement: string;
}

export interface IReverseEnglisherZugSpecial {
  vt: number;
  hr: number;
}
export interface IReverseEnglisherZug {
  ab: number;
  cd: number;
}

export interface ICartItem {
  brandId: string;
  comments?: string;
  readonly type: CartItemType;
  readonly subType?: CartSubType;
  readonly itemno: string;
  readonly index: number;
  readonly name: string;
  readonly priceTotal: number;
  readonly pricePer: number;
  readonly discountPercentage: number;
  readonly breakageFeeIncluded: boolean;
  readonly amount: number;
  readonly minAmount: number;
  readonly amountStep: number;
  readonly description: string;
  readonly fragt?: number;
  readonly priceDetails: {
    readonly [options: string]: number;
    total: number;
  };
  readonly orderDetails: {
    readonly name: string,
    readonly detail: string,
    readonly price: number
  }[];
  readonly options: { readonly [options: string]: any; };
  readonly externalShopName?: ExternalShopName;
  externalShopSuccess?: boolean;
  externalShopError?: string;
  oneDeliverySize: number;
  oneDeliveryDoublePallet: boolean;
}

export interface IAddCartItem {
  brandId: string;
  amount: number;
  amountStep?: number;
  comments?: string;
  description?: string;
  fragt?: number;
  itemno: string;
  name: string;
  minAmount?: number;
  options?: { [option: string]: any };
  price?: number;
}

export enum CartItemType {
  additional = 'additional',
  carcass = 'carcass',
  drawer = 'drawer',
  extra = 'extra',
  fronts = 'fronter',
  other = 'other',
  runner = 'udtraek',
  hettichRunnerAddOn = 'synkronstang'
}

export interface ITypedAddCartItem extends IAddCartItem {
  type: CartItemType;
  subType?: CartSubType;
  asGenerated?: boolean;
  externalShopName?: ExternalShopName;
  oneDeliveryDoublePallet?: boolean;
  oneDeliverySize?: number;
}

export interface IDrawerAddCartItem extends IAddCartItem {
  options: IDrawerOptions;
}

export interface ITypedDrawerAddCartItem extends IDrawerAddCartItem, ITypedAddCartItem {
  options: IDrawerOptions;
}

export interface IPurewoodFrontOptions {
  category: FrontCategory;
  design: FrontDesign;
  fileId?: string;
  frontType: string;
  [OptionProperty.height]: number;
  [OptionProperty.width]: number;
  [OptionProperty.frontDrillType]: string;
  [OptionProperty.frontGrip]: string;
  [OptionProperty.frontXGrip]?: number;
}

export interface IPurewoodPaintedFrontOptions extends IPurewoodFrontOptions {
  colour: string;
  [OptionProperty.thickness]?: string;
  [OptionProperty.surfaceTreatment]?: string;
  [OptionProperty.shakerBorderWidth]?: number;
  [OptionProperty.edgeRounding]?: string;
}

export interface IPurewoodLinoleumFrontOptions extends IPurewoodFrontOptions {
  colour: string;
  [OptionProperty.typeOfWood]?: string;
  [OptionProperty.thickness]: string;
  [OptionProperty.edgeRounding]: string;
}

export interface IPurewoodWoodenFrontOptions extends IPurewoodFrontOptions {
  [OptionProperty.surfaceTreatment]: string;
  [OptionProperty.typeOfWood]: string;
  [OptionProperty.veneerDirection]: string;
  [OptionProperty.shakerBorderWidth]?: number;
  [OptionProperty.edgeRounding]?: string;
}

export type IAddCart = ITypedAddCartItem[];

export interface RepeatCartItem {
  contents: { readonly [options: string]: any; };
  description: string;
  formattedType: string;
  itemno: string;
}
