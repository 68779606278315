import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rev-ezug-display',
  templateUrl: './rev-ezug-display.component.html',
  styleUrls: ['./rev-ezug-display.component.css']
})
export class RevEzugDisplayComponent implements OnInit, AfterViewInit {

  @Input() isLocked: boolean;

  @ViewChild('revEzugCanvas') revEzugCanvas: ElementRef;

  revEzugMaal = {
    ab: 50,
    cd: 55,
    special: false
  };

  // maxEzugAB = 0;

  revEzugHoejder = [
    {
      label: '55',
      value: 55
    },
    {
      label: '95',
      value: 95
    },
    {
      label: '115',
      value: 115
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.drawRevEzug();
  }

  drawRevEzug() {
    console.debug('Drawing revEzugCanvas');
    const ctx: CanvasRenderingContext2D =
      this.revEzugCanvas.nativeElement.getContext('2d');

    // ezug path
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, 500, 500);
    ctx.fillStyle = '#000';
    const outerPath = new Path2D('M 30 40 L 80 40 C 130 40 120 70 160 70 L 210 70 L 210 120 L 30 120 L 30 40 Z');
    // const ab = this.revEzugMaal.ab; const cd = this.revEzugMaal.cd;
    ctx.font = '14px Arial';
    ctx.stroke(outerPath);

    // hjælpelinjer
    ctx.moveTo(35, 30);
    ctx.lineTo(90, 30);
    ctx.fillText('A               B', 24, 35);

    ctx.fillText('C', 214, 65);
    ctx.moveTo(218, 70);
    ctx.lineTo(218, 110);
    ctx.fillText('D', 214, 125);

    ctx.textAlign = 'center';
    ctx.fillText('min. 50 mm.', 64, 15);
    ctx.textAlign = 'left';
    // ctx.fillText(cd + '', 225, 95);

    ctx.stroke();
    //  markeringer (a-b, c-e, d-f)
  }

}
