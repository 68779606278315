import { IRelatedProductSearchResult } from './IRelatedProduct';
import { IUser } from './IUser';
import { RepeatCartItem } from './ICartItem';

export enum Manufacturer {
  ernstMair = 'ernst-mair',
  nothegger = 'nothegger',
  purewood = 'purewood',
}

export enum ManufacturerWithSuffix {
  PurewoodDrawer = Manufacturer.purewood+'-drawer'
}

export enum FrontCategory {
  linoleum = 'linoleum',
  painted = 'painted',
  veneer = 'veneer',
  wooden = 'wooden'
}

export enum FrontDesign {
  NO_DESIGN = 'no-design',
  STANDARD_DESIGN = 'standard',
  STANDARD_PLUS_DESIGN = 'standard-plus',
  UNIQUE_DESIGN = 'design',
  OWN_DESIGN = 'own-design',
  EDGE_2MM_DESIGN = 'edge-2mm-design',
  EDGE_5MM_DESIGN = 'edge-5mm-design',
}

export interface IProductCategoryResponse {
  id: number;
  name: string;
  description?: string;
  freightRate: number;
  order?: number;
  imageUrl?: string;
  parentId?: number;
}

export interface IProductCategoryTreeResponse {
  id?: number;
  name: string;
  description: string;
  freightRate?: number;
}

interface DynamicProductBase {
  id: number;
  link: string;
  itemNumber: string;
  isSet: boolean;
  oneDeliverySize: number;
  oneDeliveryDoublePallet: boolean;
}

export interface IFullProductLocaleResponse {
  id: number;
  lang: string;
  name: string;
  description: string;
  price: number;
  breakageFeeCount: number;
}

export interface IDynamicProductWithLocales extends DynamicProductBase {
  categoryId?: number;
  relatedProducts: IRelatedProductSearchResult[];
  locale: IFullProductLocaleResponse[];
}

export interface IDynamicProduct extends DynamicProductBase {
  categoryId: number;
  name: string;
  description: string;
  price: number;
  breakageFeeCount: number;
  imageUrl?: string;
}

export interface IDynamicProductWithRelated extends IDynamicProduct {
  relatedProducts: IRelatedProductSearchResult[];
}

export interface IFullCategoryResponse {
  id: number;
  parentId: number;
  order: number;
  locale: IFullCategoryLocaleResponse[];
}

export interface IFullCategoryLocaleResponse {
  id: number;
  lang: string;
  name: string;
  description: string;
  freightRate: number;
  imageUrl?: string;
  parentId?: number;
}


export interface IFrontHistory extends RepeatCartItem {
  id?: number;
  manufacturer: Manufacturer.purewood;
  user?: IUser;
}

export interface IFront {
  description: string;
  design: FrontDesign;
  html3d?: string;
  id: string;
  image: string;
  minimumSize?: number;
  name: string;
  pdf2d?: string;
  sortOrder: number;
}

export interface IOtherProduct {
  id: number;
  name: string;
  img: string;
  description?: string;
  shortname: string;
  options: string;
  enabled: boolean;
}

export enum OtherProductShortName {
  fronts = 'fronts',
  profiles = 'profiles',
  solidOrganiser = 'solidOrganiser',
  trashcanDrawer = 'trashcanDrawer',
  sortissimoFastline = 'sortissimoFastline',
  sortissimoOrganiser = 'sortissimoOrganiser',
  slacksDrawer = 'slacksDrawer',
  cupboard = 'cupboard',
  wallUnit = 'wallUnit',
  baseUnit = 'baseUnit',
  board = 'board',
  careSet = 'careSet',
}

export enum OptionProperty {
  articleNumber = 'articleNumber',
  bottomUnmounted = 'loesBund',
  comment = 'comment',
  cornerJoin = 'cornerJoin',
  couplingAlongside = 'koblingLeveret', // Must include 'kobling'
  depth = 'depth',
  drawerBase = 'bund',
  drawerOuterWidth = 'lysmaal', // Must include 'lysmaal'
  extras = 'extras',
  frontDrillType = 'frontDrillType',
  frontGrip = 'frontGrip',
  frontXGrip = 'frontXGrip',
  fscCertified = 'fsc',
  height = 'height',
  joint = 'samling',
  plinth = 'plinth',
  premountedCoupling = 'formonteretKobling', // Must be 'formonteretKobling'
  runnerDepth = 'skinneDybde', // Must include 'skinne'
  runnerDepthSelect = 'skinneDybdeSelect', // Must start with the value of runnerDepth
  runnerLoad = 'udtraekVaegt',
  runnerMark = 'udtraek',
  runnerPushToOpenSilent = 'udtraekPushToOpenSilent',
  runnerType = 'udtraekType',
  shimsSelection = 'afstandsListe',
  slideList = 'foeringsListe',
  shelfHoles = 'shelfHoles',
  shelves = 'shelves',
  surfaceTreatment = 'overflade',
  surfaceTreatmentUnderside = 'surfaceTreatmentUnderside',
  suspensionFittings = 'suspensionFittings',
  synchronisationBar = 'udtraekSynkronstang',
  thickness = 'thickness',
  type = 'type',
  typeOfWood = 'traesort',
  veneerDirection = 'veneerDirection',
  shakerBorderWidth = 'shakerBorderWidth',
  veneer = 'veneer',
  width = 'width',
  woodQuality = 'kvalitet',
  edgeRounding = 'edgeRounding'
}

export enum OptionThickness {
  Purewood13_5MM = "13_5mm_pw",
  Purewood14_6MM = "14_6mm_pw",
}

export enum OptionType {
  cabinet = 'cabinet',
  superior = 'superior',
  yacht = 'yacht',
}

export interface IOption {
  id: number;
  property: OptionProperty;
  value: string;
  label: string;
  index: number;
  types?: string[];
  externalId?: string;
}

export interface IItemNumber {
  id: number;
  hash: string;
  varenr: string;
}


export enum ImageMessageTypes {
  base64 = 'BASE_64_IMG',
  error = 'ERROR',
}
export interface IImage {
  encImg: string;
  message: ImageMessageTypes;
  errorMessage?: string;
}
