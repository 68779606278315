import { DecimalPipe, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import { DynamicFormsBasicUIModule } from '@ng-dynamic-forms/ui-basic';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ArchwizardModule } from 'angular-archwizard';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BlockUIModule } from 'ng-block-ui';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { NgxUploaderModule } from 'ngx-uploader';
import { firstValueFrom } from 'rxjs';

import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AdminCategoryLocaleComponent } from './admin/admin-category/admin-category-locale/admin-category-locale.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCompaniesComponent } from './admin/admin-companies/admin-companies.component';
import { AdminCompanyComponent } from './admin/admin-company/admin-company.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminErnstMairComponent } from './admin/admin-ernst-mair/admin-ernst-mair.component';
import { AdminFreightComponent } from './admin/admin-freight/admin-freight.component';
import { AdminGlobalContentComponent } from './admin/admin-global-content/admin-global-content.component';
import { GlobalContentEditorComponent } from './admin/admin-global-content/global-content-editor/global-content-editor.component';
import { AdminNavbarComponent } from './admin/admin-navbar/admin-navbar.component';
import { AdminOrderComponent } from './admin/admin-order/admin-order.component';
import { AdminOrdersComponent } from './admin/admin-orders/admin-orders.component';
import { AdminProductsAllComponent } from './admin/admin-products-all/admin-products-all.component';
import { AdminProductsLanguageComponent } from './admin/admin-products/admin-products-language/admin-products-language.component';
import { AdminNewComponentLocaleComponent } from './admin/admin-products/admin-products-new/admin-new-component-locale/admin-new-component-locale.component';
import { AdminProductsNewComponent } from './admin/admin-products/admin-products-new/admin-products-new.component';
import { RelatedProductsEditorComponent } from './admin/admin-products/admin-products-new/related-products-editor/related-products-editor.component';
import { AdminProductsComponent } from './admin/admin-products/admin-products.component';
import { ItemNumberComponent } from './admin/admin-products/item-number/item-number.component';
import { ProductPriceComponent } from './admin/admin-products/product-price/product-price.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminViewOnlyDirective } from './admin/admin-view-only.directive';
import { AdminComponent } from './admin/admin.component';
import { CustomerViewOnlyDirective } from './admin/customer-view-only.directive';
import { EditErnstMairComponent } from './admin/edit-ernst-mair/edit-ernst-mair.component';
import { GeneralEditorComponent } from './admin/general-editor/general-editor.component';
import { InfoEditorLocaleComponent } from './admin/info-editor-locale/info-editor-locale.component';
import { InfoEditorComponent } from './admin/info-editor/info-editor.component';
import { ProductPathComponent } from './admin/product-path/product-path.component';
import { ProductsComponent } from './admin/products/products.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './auth/token.interceptor';
import { OtherProductBaseComponent } from './base-component/other-product-base/other-product-base.component';
import { VeneerProductBaseComponent } from './base-component/veneer-product-base/veneer-product-base.component';
import { CartComponent } from './cart/cart.component';
import { BackButtonDirective } from './directives/backButton.directive';
import { FocusDirective } from './directives/focus.directive';
import { OnlyVisibleBeforeLoginDirective } from './directives/onlyVisibleBeforeLogin.directive';
import { OnlyVisibleForUsersDirective } from './directives/onlyVisibleForUsers.directive';
import { PostalCodeDirective } from './directives/postalcodeDirective';
import { DynamicOrderComponent } from './dynamic-order/dynamic-order.component';
import { DynamicFieldDirective } from './dynamicForm/dynamic-field.directive';
import { DynamicFormComponent } from './dynamicForm/dynamic-form/dynamic-form.component';
import { ButtonComponent } from './dynamicForm/fields/button/button.component';
import { CheckboxComponent } from './dynamicForm/fields/checkbox/checkbox.component';
import { HTMLFieldComponent } from './dynamicForm/fields/htmlfield/htmlfield.component';
import { InputComponent } from './dynamicForm/fields/input/input.component';
import { SelectComponent } from './dynamicForm/fields/select/select.component';
import { ErnstMairComponent } from './ernst-mair/ernst-mair.component';
import { FaqDescriptionsComponent } from './faq/descriptions/descriptions.component';
import { FaqComponent } from './faq/faq.component';
import { FaqQualityComponent } from './faq/quality/quality.component';
import { AddToBasketComponent } from './general-product-category/add-to-basket/add-to-basket.component';
import { GeneralProductCategoryComponent } from './general-product-category/general-product-category.component';
import { GeneralProductComponent } from './general-product-category/general-product/general-product.component';
import { HomeCarouselComponent } from './home/home-caurosel/home-carousel.component';
import { HomeComponent } from './home/home.component';
import { AddLocaleQuerystringParameterToHttpRequestsInterceptor } from './interceptors/AddLocaleQuerystringParameterToHttpRequests.interceptor';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { LogoPreviewComponent } from './logo-preview/logo-preview.component';
import { LogoUploaderComponent } from './logo-uploader/logo-uploader.component';
import { LogoutComponent } from './logout/logout.component';
import { FooterComponent } from './master-layout/footer/footer.component';
import { MasterLayoutComponent } from './master-layout/master-layout.component';
import { NavBarComponent } from './master-layout/nav-bar/nav-bar.component';
import { AddToCartWithAmountModalComponent } from './modals/add-to-cart-with-amount-modal/add-to-cart-with-amount-modal.component';
import { BestikindsatsComponent } from './nothegger/bestikindsats/bestikindsats.component';
import { BaseUnitComponent } from './nothegger/cabinet-carcass/base-unit/base-unit.component';
import { BoardComponent } from './nothegger/cabinet-carcass/board/board.component';
import { CabinetCarcassComponent } from './nothegger/cabinet-carcass/cabinet-carcass.component';
import { CupboardComponent } from './nothegger/cabinet-carcass/cupboard/cupboard.component';
import { WallUnitComponent } from './nothegger/cabinet-carcass/wall-unit/wall-unit.component';
import { CareSetComponent } from './nothegger/care-set/care-set.component';
import { NotheggerComponent } from './nothegger/nothegger.component';
import { ProfilesComponent } from './nothegger/profiles/profiles.component';
import { SlacksDrawerComponent } from './nothegger/slacks-drawer/slacks-drawer.component';
import { SolidOrganiserComponent } from './nothegger/solid-organiser/solid-organiser.component';
import { SortissimoFastlineComponent } from './nothegger/sortissimo-fastline/sortissimo-fastline.component';
import { SortissimoComponent } from './nothegger/sortissimo/sortissimo.component';
import { TrashcanDrawerComponent } from './nothegger/trashcan-drawer/trashcan-drawer.component';
import { OrderLayoutBodyItemComponent } from './order-layout/order-layout-body-item/order-layout-body-item.component';
import { OrderLayoutBodyTypeComponent } from './order-layout/order-layout-body-type/order-layout-body-type.component';
import { OrderLayoutBodyComponent } from './order-layout/order-layout-body/order-layout-body.component';
import { OrderLayoutHeaderComponent } from './order-layout/order-layout-header/order-layout-header.component';
import { OrderLayoutImageComponent } from './order-layout/order-layout-image/order-layout-image.component';
import { DisplayErnstMairRearEzugComponent } from './order/ernst-mair-display/display-ernst-mair-rear-ezug/display-ernst-mair-rear-ezug.component';
import { DisplayErnstMairRevEzugComponent } from './order/ernst-mair-display/display-ernst-mair-rev-ezug/display-ernst-mair-rev-ezug.component';
import { LogoAddComponent } from './order/logo-add/logo-add.component';
import { PurewoodRevEzugDisplayComponent } from './order/purewood-rev-ezug-display/purewood-rev-ezug-display.component';
import { PurewoodRevEzugSpecialDisplayComponent } from './order/purewood-rev-ezug-special-display/purewood-rev-ezug-special-display.component';
import { RearUdsparingComponent } from './order/rear-udsparing/rear-udsparing.component';
import { RevEzugDisplayComponent } from './order/rev-ezug-display/rev-ezug-display.component';
import { RevEzugSpecialDisplayComponent } from './order/rev-ezug-special-display/rev-ezug-special-display.component';
import { SiphonCuttingComponent } from './order/udsparing/siphon-cutting.component';
import { NewLineToBRPipe } from './pipes/newLineToBR.pipe';
import { OrderDetailPipe } from './pipes/orderDetail.pipe';
import { ProductTypeComponent } from './product-type/product-type.component';
import { CompanyAdminComponent } from './profile/company-admin/company-admin.component';
import { MyOrdersComponent } from './profile/my-orders/my-orders.component';
import { MyProfileComponent } from './profile/my-profile/my-profile.component';
import { OrderDetailsComponent } from './profile/order-details/order-details.component';
import { PurewoodDrawersComponent } from './purewood/purewood-drawers/purewood-drawers.component';
import { PurewoodFrontOptionsComponent } from './purewood/purewood-fronts/purewood-front/purewood-front-options/purewood-front-options.component';
import { PurewoodFrontComponent } from './purewood/purewood-fronts/purewood-front/purewood-front.component';
import { PurewoodFrontsComponent } from './purewood/purewood-fronts/purewood-fronts.component';
import { RepeatPurewoodFrontComponent } from './purewood/purewood-fronts/repeat-purewood-front/repeat-purewood-front.component';
import { PurewoodComponent } from './purewood/purewood.component';
import { RepeatDrawerComponent } from './repeat-drawer/repeat-drawer.component';
import { RootModalComponent } from './root-modal/root-modal.component';
import { ScheduleMeetingComponent } from './schedule-meeting/schedule-meeting.component';
import { APIService, CartService, LocaleService, LoggingService, ReportToApiMIssingTranslationHandler } from './services';
import { CompanyUserComponent } from './signup/company-user/company-user.component';
import { SignupComponent } from './signup/signup.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { AdminApproveUserComponent } from './utils/admin-approve-user/admin-approve-user.component';
import { AmountStepComponent } from './utils/amount-step/amount-step.component';
import { BlockUITemplateComponent } from './utils/block-uitemplate/block-uitemplate.component';
import { BreadcrumbsComponent } from './utils/breadcrumbs/breadcrumbs.component';
import { CanPurchaseViewComponent } from './utils/can-purchase-view/can-purchase-view.component';
import { NcsColorPickerComponent } from './utils/ncs-color-picker/ncs-color-picker.component';
import { NcsOrRalColorPickerComponent } from './utils/ncs-or-ral-color-picker/ncs-or-ral-color-picker.component';
import { RalColorPickerComponent } from './utils/ral-color-picker/ral-color-picker.component';
import { RemoveButtonComponent } from './utils/remove-button/remove-button.component';

registerLocaleData(localeDa, localeDaExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + '/options/translations/', '');
}

export function AppInitializerFactory(localeService: LocaleService, translateService: TranslateService, cartService: CartService) {
  return async () => {

    console.debug('Initializing TranslateService...');

    const langToSet = localeService.getLanguage();
    translateService.setDefaultLang(langToSet);

    try {
      await firstValueFrom(translateService.use(langToSet));
    } catch (err) {
      console.error('Unable to load translations.', err);
    }

    console.debug('Initialized TranslateService');


    console.debug('Initializing CartService...');

    await cartService.initialize();

    console.debug('Initialized CartService!');

  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    HomeComponent,
    ProductsComponent,
    LogoutComponent,
    CartComponent,
    OrderDetailPipe,
    NewLineToBRPipe,
    AdminComponent,
    AdminNavbarComponent,
    AdminDashboardComponent,
    AdminOrdersComponent,
    MasterLayoutComponent,
    SiphonCuttingComponent,
    RootModalComponent,
    SignupComponent,
    AdminOrderComponent,
    AdminUsersComponent,
    ResetPasswordComponent,
    CompanyAdminComponent,
    FaqComponent,
    FaqQualityComponent,
    FaqDescriptionsComponent,
    AdminCompanyComponent,
    AdminCompaniesComponent,
    PostalCodeDirective,
    SortissimoComponent,
    MyProfileComponent,
    MyOrdersComponent,
    OrderDetailsComponent,
    CompanyUserComponent,
    LogoUploaderComponent,
    LogoAddComponent,
    InputComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    ButtonComponent,
    CheckboxComponent,
    SelectComponent,
    HTMLFieldComponent,
    DynamicOrderComponent,
    BlockUITemplateComponent,
    AdminProductsComponent,
    AdminProductsNewComponent,
    RevEzugDisplayComponent,
    RevEzugSpecialDisplayComponent,
    PurewoodRevEzugDisplayComponent,
    PurewoodRevEzugSpecialDisplayComponent,
    UnsupportedBrowserComponent,
    ProfilesComponent,
    ProductPathComponent,
    AdminNewComponentLocaleComponent,
    AdminProductsLanguageComponent,
    AdminViewOnlyDirective,
    CustomerViewOnlyDirective,
    BackButtonDirective,
    OnlyVisibleBeforeLoginDirective,
    OnlyVisibleForUsersDirective,
    AdminCategoryComponent,
    AdminCategoryLocaleComponent,
    RemoveButtonComponent,
    FocusDirective,
    AddToBasketComponent,
    RelatedProductsEditorComponent,
    ItemNumberComponent,
    ProductPriceComponent,
    LanguageSelectorComponent,
    InfoEditorComponent,
    InfoEditorLocaleComponent,
    BestikindsatsComponent,
    GeneralEditorComponent,
    AdminErnstMairComponent,
    EditErnstMairComponent,
    DisplayErnstMairRearEzugComponent,
    DisplayErnstMairRevEzugComponent,
    RearUdsparingComponent,
    RepeatDrawerComponent,
    PurewoodFrontsComponent,
    PurewoodFrontComponent,
    PurewoodFrontOptionsComponent,
    RalColorPickerComponent,
    ErnstMairComponent,
    NotheggerComponent,
    SolidOrganiserComponent,
    TrashcanDrawerComponent,
    SortissimoFastlineComponent,
    SlacksDrawerComponent,
    ScheduleMeetingComponent,
    CupboardComponent,
    BaseUnitComponent,
    WallUnitComponent,
    BoardComponent,
    CabinetCarcassComponent,
    AmountStepComponent,
    OtherProductBaseComponent,
    VeneerProductBaseComponent,
    PurewoodComponent,
    BreadcrumbsComponent,
    AdminApproveUserComponent,
    NcsOrRalColorPickerComponent,
    NcsColorPickerComponent,
    PurewoodDrawersComponent,
    RepeatPurewoodFrontComponent,
    GeneralProductCategoryComponent,
    GeneralProductComponent,
    FooterComponent,
    HomeCarouselComponent,
    OrderLayoutBodyComponent,
    OrderLayoutBodyItemComponent,
    OrderLayoutBodyTypeComponent,
    GlobalContentEditorComponent,
    AdminGlobalContentComponent,
    ProductTypeComponent,
    OrderLayoutHeaderComponent,
    AdminProductsAllComponent,
    CareSetComponent,
    OrderLayoutImageComponent,
    AdminFreightComponent,
    CanPurchaseViewComponent,
    AddToCartWithAmountModalComponent,
    LogoPreviewComponent,
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsBasicUIModule,
    HttpClientModule,
    FormsModule,
    ArchwizardModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      toastClass: 'ngx-toastr mt-5'
    }),
    NgxChartsModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      delayStop: 500,
      template: BlockUITemplateComponent
    }),
    NgxUploaderModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ReportToApiMIssingTranslationHandler },
    }),
    AutocompleteLibModule,
    QuillModule.forRoot({
      placeholder: '',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
          [{ size: ['small', false, 'large', 'huge'] }]
        ],
      }
    }),
    TabsModule,
    NgOptimizedImage,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddLocaleQuerystringParameterToHttpRequestsInterceptor, multi: true },
    DecimalPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [LocaleService, TranslateService, CartService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  loggingSetup: Promise<void>;

  constructor(private api: APIService, private loggingService: LoggingService) {
    library.add(fas, far);
    this.loggingSetup = loggingService.setLogging(api);
  }
}
