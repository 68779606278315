<block-ui>
    <div *ngIf="front" class="container">

        <div class="row">

            <div class="col-12">
                <app-breadcrumbs [currentBreadcrumb]="breadcrumb"></app-breadcrumbs>
                <hr class="mb-3"/>
            </div>

        </div>
        <div class="row">

            <div class="col-9">
                <app-dynamic-form (change)="validateForm()" 
                                  (formCreated)="formWasCreated()"
                                  *ngIf="[FrontCategoryEnum.veneer, FrontCategoryEnum.wooden + ''].includes(frontCategory) && model"
                                  [autoFocusFirst]="true" 
                                  [rows]="model"></app-dynamic-form>

                <form [formGroup]="frontForm" novalidate>

                    <div *ngIf="frontCategory === FrontCategoryEnum.painted" class="row">

                        <div *ngIf="front.design === FrontDesign.OWN_DESIGN" class="col-6 mb-3">
                            <label class="form-label" for="ownDesign">{{ 'FRONTS.YOUR_DESIGN' | translate }}</label>
                            <input (uploadOutput)="onUploadOutput($event)" [options]="uploadOptions" [uploadInput]="uploadInput"
                                   accept="application/pdf" class="form-control" id="ownDesign" ngFileSelect type="file"/>
                        </div>
                        <div *ngIf="front.design === FrontDesign.NO_DESIGN" class="col-6 mb-3">
                            <label class="form-label" for="painted_thickness">{{ 'FRONTS.THICKNESS' | translate }}</label>
                            <select [formControlName]="OptionProperty.thickness" class="form-control" id="painted_thickness">
                                <option value="null">{{ 'FRONTS.SELECT_THICKNESS' | translate }}</option>
                                <option *ngFor="let t of thicknesses" value="{{ t.value }}">{{ t.label | translate }}</option>
                            </select>
                        </div>
                        <div class="col-12 mb-3 row">
                            <div class="col-6">
                                <app-ncs-or-ral-color-picker (paletteColourChange)="colourChanged($event)"
                                                             [paletteColour]="initialColour"></app-ncs-or-ral-color-picker>
                            </div>
                            <div class="col-6">
                                <label class="form-label" for="painted_surface_treatment">{{ 'Overflade' | translate }}</label>
                                <select [formControlName]="OptionProperty.surfaceTreatment" class="form-control" id="painted_surface_treatment">
                                    <option value="null">{{ 'Vælg overflade' | translate }}</option>
                                    <option *ngFor="let t of surfaceTreatments" value="{{ t.value }}">{{ t.label | translate }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-6">
                                <label class="form-label" for="edgeRounding">{{ 'fronts.description.edgeRounding.label' | translate }}</label>
                                <select [formControlName]="OptionProperty.edgeRounding" class="form-control" id="edgeRounding">
                                    <option value="null">{{ 'Vælg' | translate }} {{ 'fronts.description.edgeRounding.label' | translate }}...</option>
                                    <option *ngFor="let t of edgeRoundingOptions" value="{{ t.value }}">{{ t.label | translate }}</option>
                                </select>
                            </div>
                            <div *ngIf="isShakerFront" class="col-6">
                                <label class="form-label" for="shaker-border-width">{{ 'fronts.description.shakerBorderWidth.label' | translate }}</label>
                                <div class="input-group">
                                    <input [formControlName]="OptionProperty.shakerBorderWidth"
                                           class="form-control"
                                           id="shaker-border-width"
                                           type="number"
                                           min="20"
                                           placeholder="{{ 'fronts.description.shakerBorderWidth.placeholder' | translate }}"/>
                                    <span class="input-group-text">mm</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="countryCode && freightSetting" class="col-12">
                            <div [translateParams]="{fee: freightSetting.purewood.colourStartup.fee[countryCode] | number: '1.0-0', threshold: freightSetting.purewood.colourStartup.threshold | number: '1.0-0'}"
                                 [translate]="'FRONTS.COLOUR_FEE_INFO'" class="alert alert-info"></div>
                        </div>

                    </div>
                    <div *ngIf="frontCategory === FrontCategoryEnum.linoleum" class="row">

                        <div *ngIf="!onlyBasicInput" class="col-12 col-lg-6 mb-3">
                            <app-dynamic-form (change)="validateForm()" (formCreated)="formWasCreated()" [rows]="model"></app-dynamic-form>
                        </div>

                        <div class="col-12 col-lg-6 mb-3">
                            <label class="form-label" for="linoleum-colour">{{ 'FRONTS.LINOLEUM_COLOUR' | translate }}</label>
                            <div>
                                <app-ncs-color-picker (colourChange)="colourChanged($event)" [colourSet]="linoleumColours"
                                                      [colour]="getInitialColour()" id="linoleum-colour"></app-ncs-color-picker>
                            </div>
                        </div>

                        <div *ngIf="!onlyBasicInput" class="col-8 offset-2 col-lg-6 offset-lg-0 mb-3">
                            <img alt="Image of front" class="img-fluid" src="/assets/images/fronts/Linoleum-wooden-edge.png">
                        </div>

                        <div class="col-12 col-lg-6 mb-3">
                            <div class="mb-3">
                                <label class="form-label" for="linoleum_thickness">{{ 'FRONTS.THICKNESS' | translate }}</label>
                                <select [formControlName]="OptionProperty.thickness" class="form-control" id="linoleum_thickness">
                                    <option *ngIf="thicknesses.length > 1" value="null">{{ 'FRONTS.SELECT_THICKNESS' | translate }}</option>
                                    <option *ngFor="let t of thicknesses" value="{{ t.value }}">{{ t.label | translate }}</option>
                                </select>
                                <small class="text-muted">{{ 'FRONTS.LINOLEUM_THICKNESS_DESCRIPTION' | translate }}</small>
                            </div>

                            <div>
                                <label class="form-label" for="edgeRounding">{{ 'fronts.description.edgeRounding.label' | translate }}</label>
                                <select [formControlName]="OptionProperty.edgeRounding" class="form-control" id="edgeRounding">
                                    <option value="null">{{ 'Vælg' | translate }} {{ 'fronts.description.edgeRounding.label' | translate }}...</option>
                                    <option *ngFor="let t of edgeRoundingOptions" value="{{ t.value }}">{{ t.label | translate }}</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-6">
                            <label class="form-label">{{ 'fronts.drilling.label' | translate }}</label>
                            <ul class="list-group">
                                <li class="list-group-item d-flex align-items-start">
                                    <div class="ms-2 me-auto">
                                        {{ 'fronts.drilling.hingesIkeaMetod' | translate }}<br>
                                        <strong>{{ 'fronts.drilling.hingesIkeaMetodLeft.short' | translate }}</strong>: {{ 'fronts.drilling.openLeft' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.drilling.hingesIkeaMetodRight.short' | translate }}</strong>: {{ 'fronts.drilling.openRight' | translate }}
                                    </div>
                                    <div>
                                        <a href="/assets/frontDrilling/hingesIkeaMetod.pdf" class="text-decoration-none" target="_blank"
                                           tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex align-items-start">
                                    <div class="ms-2 me-auto">
                                        {{ 'fronts.drilling.hingesUP' | translate }}<br>
                                        <strong>{{ 'fronts.drilling.hingesUPLeft.short' | translate }}</strong>: {{ 'fronts.drilling.openLeft' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.drilling.hingesUPRight.short' | translate }}</strong>: {{ 'fronts.drilling.openRight' | translate }}
                                    </div>
                                    <div>
                                        <a href="/assets/frontDrilling/hingesUP.pdf" class="text-decoration-none" target="_blank" tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="">
                                        {{ 'fronts.drilling.drawerIkeaMetod.long' | translate }}<br>
                                        <strong>{{ 'fronts.drilling.drawerIkeaMetod.short' | translate }}</strong>
                                    </div>
                                    <div>
                                        <a href="/assets/frontDrilling/DrawerIkeaMetod.pdf" class="text-decoration-none" target="_blank"
                                           tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>
                            </ul>
                            <div class="form-text" [innerText]="'fronts.drilling.description' | translate"></div>

                        </div>

                        <div class="col-6" *ngIf="drilledGripsAvailable">
                            <label class="form-label">{{ 'fronts.grip.label' | translate }}</label>
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        {{ 'fronts.grip.round.name' | translate }}<br>
                                        <strong>{{ 'fronts.grip.round.RC.short' | translate }}</strong>: {{ 'fronts.grip.round.RC.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.round.RR.short' | translate }}</strong>: {{ 'fronts.grip.round.RR.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.round.RL.short' | translate }}</strong>: {{ 'fronts.grip.round.RL.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.round.RC-X.short' | translate }}</strong>: {{ 'fronts.grip.round.RC-X.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.round.RR-X.short' | translate }}</strong>: {{ 'fronts.grip.round.RR-X.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.round.RL-X.short' | translate }}</strong>: {{ 'fronts.grip.round.RL-X.long' | translate }}
                                        <br>
                                    </div>
                                    <div>
                                        <a href="/assets/frontGrip/Greb%20Round.pdf" class="text-decoration-none" target="_blank" tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        {{ 'fronts.grip.square.name' | translate }}<br>
                                        <strong>{{ 'fronts.grip.square.SC.short' | translate }}</strong>: {{ 'fronts.grip.square.SC.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SR.short' | translate }}</strong>: {{ 'fronts.grip.square.SR.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SL.short' | translate }}</strong>: {{ 'fronts.grip.square.SL.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SC-X.short' | translate }}</strong>: {{ 'fronts.grip.square.SC-X.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SR-X.short' | translate }}</strong>: {{ 'fronts.grip.square.SR-X.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SL-X.short' | translate }}</strong>: {{ 'fronts.grip.square.SL-X.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SLVX.short' | translate }}</strong>: {{ 'fronts.grip.square.SLVX.long' | translate }}
                                        <br>
                                        <strong>{{ 'fronts.grip.square.SRVX.short' | translate }}</strong>: {{ 'fronts.grip.square.SRVX.long' | translate }}
                                        <br>
                                    </div>
                                    <div>
                                        <a href="/assets/frontGrip/Greb%20Square.pdf" class="text-decoration-none" target="_blank" tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        {{ 'fronts.grip.J.name' | translate }}<br>
                                        <strong>{{ 'fronts.grip.J.JT.short' | translate }}</strong>: {{ 'fronts.grip.J.JT.long' | translate }}<br>
                                        <strong>{{ 'fronts.grip.J.JB.short' | translate }}</strong>: {{ 'fronts.grip.J.JB.long' | translate }}<br>
                                        <strong>{{ 'fronts.grip.J.JR.short' | translate }}</strong>: {{ 'fronts.grip.J.JR.long' | translate }}<br>
                                        <strong>{{ 'fronts.grip.J.JL.short' | translate }}</strong>: {{ 'fronts.grip.J.JL.long' | translate }}<br>
                                    </div>
                                    <div>
                                        <a href="/assets/frontGrip/Greb%20J.pdf" class="text-decoration-none" target="_blank" tabindex="-1">
                                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                                        </a>
                                    </div>
                                </li>

                                <li class="list-group-item">
                                    <label class="form-label" for="gripsDesign">
                                        <strong [innerText]="'Own Design' | translate"></strong>: {{ 'FRONTS.LINOLEUM_GRIPS_DESIGN' | translate }}
                                    </label>

                                    <input type="file"
                                           ngFileSelect
                                           [options]="uploadOptions" 
                                           (uploadOutput)="onUploadOutput($event)" 
                                           [uploadInput]="uploadInput"
                                           accept="application/pdf"
                                           class="form-control"
                                           id="gripsDesign" />

                                    <small class="text-muted">{{ 'FRONTS.LINOLEUM_GRIPS_PRICE' | translate }}</small>
                                </li>
                            </ul>
                            <div class="form-text" [innerText]="'fronts.grip.description' | translate"></div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="col-3 text-center">
                <figure>
                    <div class="front-image-container">
                        <img alt="Image of front" class="img-fluid" src="/assets/images/fronts/{{front.image}}">
                        <fa-icon *ngIf="veneerDirection === 'horizontal'" 
                                [icon]="faLeftRight" 
                                class="direction-arrow text-primary">
                        </fa-icon>
                        <fa-icon *ngIf="veneerDirection === 'vertical'" 
                                [icon]="faUpDown" 
                                class="direction-arrow text-primary">
                        </fa-icon>
                        <span *ngIf="frontCategory === FrontCategoryEnum.veneer" class="dimension-label width-label">{{ 'fronts.dimensions.width' | translate }}</span>
                        <span *ngIf="frontCategory === FrontCategoryEnum.veneer" class="dimension-label height-label">{{ 'fronts.dimensions.height' | translate }}</span>
                    </div>
                    <figcaption *ngIf="front.html3d || front.pdf2d">
                        <a *ngIf="front.html3d" [href]="'/assets/fronts/' + front.html3d" class="text-decoration-none" target="_blank" tabindex="-1">
                            {{ 'FRONTER.PREVIEW_3D' | translate}}
                        </a>
                        <ng-container *ngIf="front.html3d && front.pdf2d">&nbsp;&sdot;&nbsp;</ng-container>
                        <a *ngIf="front.pdf2d" [href]="'/assets/fronts/' + front.pdf2d" class="text-decoration-none" target="_blank" tabindex="-1">
                            {{ 'FRONTER.PREVIEW_2D' | translate}}
                        </a>
                    </figcaption>
                </figure>
            </div>

        </div>
        <div class="row">

            <div class="col-12">
                <hr/>
            </div>

        </div>
        <div class="row">

            <div class="col-12">
                <label class="form-label">{{ 'FRONTS.DIMENSIONS' | translate }}</label>
                <ng-container *ngFor="let option of dimensionRows; let i = index">
                    <app-front-type-options
                            [index]="i"
                            [customGripEnabled]="frontDesignFileId !== undefined && frontDesignFileId !== null"
                            (delete)="deleteOption(i)"
                            (dimensionsValid)="validateDimensions()"
                            [gripEnabled]="drilledGripsAvailable"
                            [minimumSize]="front.minimumSize ?? purewoodFrontDefaultMinSize"
                            [option]="option"
                            (triggerAddRow)="addOption(i)"
                    />
                </ng-container>
            </div>

        </div>
        <div *ngIf="dimensionRows.length" class="row">

            <div class="col-12">
                <button (click)="addOption()" class="btn btn-secondary">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    {{'Fronts.addDimension' | translate}}
                </button>
                <button (click)="addToCart()" [disabled]="!dimensionsAreValid" class="btn btn-primary float-end">
                    {{ approveButtonTextId | translate }}
                </button>
            </div>

        </div>
        <div *ngIf="!isLoggedIn && front.design !== FrontDesign.OWN_DESIGN" class="row">

            <div class="col-12 mt-4">
                <div [innerHTML]="'logInToSeePrices' | translate" class="alert alert-danger"></div>
            </div>

        </div>
    </div>
</block-ui>
