<ng-template #selectRepeatType>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'SIDEBAR.REPEAT_DRAWER' | translate }}</h4>
        <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <h5>{{ 'UTILITY.DETAILS' | translate }}</h5>
                {{ selectedDrawer.formattedType }} - {{ selectedDrawer.description }}
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <br>
                <h5 translate="Gentag som"></h5>
                <div class="list-group"
                     *ngIf='!onlySelfRepeating'>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('type-b');"
                            translate="DRAWER_NAMES.type-b"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('innenlade')"
                            translate="DRAWER_NAMES.innenlade"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('schutte')"
                            translate="DRAWER_NAMES.schutte"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('ezug')"
                            translate="DRAWER_NAMES.ezug"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('ezug_trend')"
                            translate="DRAWER_NAMES.ezug_trend"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('rev_ezug')"
                            translate="DRAWER_NAMES.rev_ezug"></button>
                    <button type="button"
                            style="cursor: pointer;"
                            class="list-group-item list-group-item-action"
                            (click)="repeatDrawer('rev_ezug_special')"
                            translate="DRAWER_NAMES.rev_ezug_special"></button>
                </div>

                <!--Ernst repeat -->
                <div *ngIf='!onlySelfRepeating && userService.isAllowedErnstMair()'>
                    <h5 class="text-center mt-4">Ernst Mair</h5>
                    <div class="list-group">
                        <button (click)="repeatDrawer('ernst-mair-type-b')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.ernst-mair-type-b' | translate }}</button>
                        <button (click)="repeatDrawer('ernst-mair-innenlade')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.ernst-mair-innenlade' | translate }}
                        </button>
                        <button (click)="repeatDrawer('ernst-mair-schutte')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.ernst-mair-schutte' | translate }}
                        </button>
                        <button (click)="repeatDrawer('ernst-mair-rear-ezug')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.ernst-mair-rear-ezug' | translate }}
                        </button>
                        <button (click)="repeatDrawer('ernst-mair-rev-ezug')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.ernst-mair-rev-ezug' | translate }}
                        </button>
                    </div>
                </div>

                <!--Purewood repeat -->
                <div *ngIf='!onlySelfRepeating && userService.isAllowedPurewood()'>
                    <h5 class="text-center mt-4">Purewood</h5>
                    <div class="list-group">
                        <button (click)="repeatDrawer('purewood-type-b')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.purewood-type-b' | translate }}
                        </button>
                        <button (click)="repeatDrawer('purewood-innenlade')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.purewood-innenlade' | translate }}
                        </button>

                        <button (click)="repeatDrawer('purewood-rev-ezug')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.purewood-rev-ezug' | translate }}
                        </button>

                        <button (click)="repeatDrawer('purewood-rev-ezug_special')" class="list-group-item list-group-item-action" style="cursor: pointer"
                                type="button">
                            {{ 'DRAWER_NAMES.purewood-rev-ezug_special' | translate }}
                        </button>
                    </div>
                </div>

                <div *ngIf='onlySelfRepeating' class="list-group">
                    <button (click)="repeatDrawer(selectedDrawer.contents.type)" class="list-group-item list-group-item-action"
                            style="cursor: pointer" type="button">
                        {{ selectedDrawer.formattedType }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                (click)="modalRef.hide()"
                translate="UTILITY.CANCEL"></button>
    </div>
</ng-template>
