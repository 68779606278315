import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { CartItemType, CartSubType, ISlacksDrawerOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { Manufacturer, OptionProperty, OtherProductShortName } from '../../../../../wdcommon/IProduct';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { APIService, CartService, ModelLoaderService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';

const productTypes = {
  ['10.0063-6']: {
    cabinetWidth: 562,
    width: 547,
    varWidth: {
      min: 500,
      max: 800
    },
    depth: 553,
    height: 88,
    treatment: 'lakeret'
  },
  ['10.0063-12']: {
    cabinetWidth: 962,
    width: 947,
    varWidth: {
      min: 800,
      max: 1100
    },
    depth: 553,
    height: 88,
    treatment: 'lakeret'
  }
};

const productTypeVar = 'productType';
const varWidthVar = 'variableWidth';
const widthVar = OptionProperty.width;
const woodTypeVar = OptionProperty.typeOfWood;
const couplingVar = 'coupling';

const widthNumberRange = 'widthNumberRange';

@Component({
  selector: 'app-slacks-drawer',
  templateUrl: './slacks-drawer.component.html',
  styleUrls: ['./slacks-drawer.component.css']
})
export class SlacksDrawerComponent extends TranslatingBase implements OnInit, AfterViewInit {
  slackBreadcrumb = defaultBreadcrumbs.notheggerSlacksDrawer;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  slacksDrawer = OtherProductShortName.slacksDrawer;
  allProductTypes = Object.entries(productTypes);
  formIsInvalid = true;
  model: FormRow[] = [];
  amount = 1;
  productOptions: any;
  widthValidator: Function;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private router: Router,
    private utilities: UtilitiesService,
    private modelLoader: ModelLoaderService,
    private api: APIService,
    private cartService: CartService,
    private toastrService: ToastrService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.model = this.modelLoader.load(this.slacksDrawer);
    this.widthValidator = (): { [s: string]: boolean; } => {
      return !this.isWidthValid() ? { [widthNumberRange]: true } : null;
    };
  }

  async ngAfterViewInit() {
    this.productOptions = await this.api.getProductOptions();
    this.loadOptions();
    this.addModelValidations();
  }

  addModelValidations() {
    // Get existing width validators
    const validWidthList = [];
    const widthValidations = this.form
      .rows.find((r) => r.fields && r.fields.find((f) => f.name === widthVar))
      .fields.find((f) => f.name === widthVar).validations || [];
    widthValidations.forEach((valid) => {
      validWidthList.push(valid.validator);
    });

    // Add width number range validation, if missing
    if (!widthValidations.some((v) => v.name === widthNumberRange)) {
      widthValidations.push({
        name: widthNumberRange,
        validator: this.widthValidator,
        message: 'SlacksDrawer.WidthInvalid'
      });
      validWidthList.push(this.widthValidator);
    }

    // Assign width validators
    this.form.getControl(widthVar).setValidators(Validators.compose(validWidthList));
  }

  public isWidthValid() {
    if (this.form) {
      const productType: string = this.form.getControl(productTypeVar).value;
      const widthInput = parseInt(this.form.getControl(widthVar).value, 10);

      if (productType && widthInput) {
        return (widthInput >= productTypes[productType].varWidth.min && widthInput <= productTypes[productType].varWidth.max);
      }
    }
    return true;
  }

  loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === woodTypeVar) {
          field.options = this.productOptions
            .filter((o) => (o.property === woodTypeVar && (o.types.indexOf(this.slacksDrawer) > -1)))
            .sort(this.utilities.abcSort);
        } else if (field && field.name === varWidthVar) {
          field.options = [];
          field.options.push({ label: this.translate('SlacksDrawer.fixedWidth'), value: '1' });
          field.options.push({ label: this.translate('SlacksDrawer.customWidth'), value: '0' });
          const varWidthControl = this.form.getControl(varWidthVar);
          varWidthControl.setValue('1');
        } else if (field && field.name === productTypeVar) {
          field.options = [];
          Object.entries(productTypes).forEach((productTypeEntry) => {
            field.options.push({ label: this.translate('SlacksDrawer.' + productTypeEntry[0]), value: productTypeEntry[0] });
          });
        }
      }
    }
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl(productTypeVar).value === type;
  }

  setType(type: string) {
    const control = this.form.getControl(productTypeVar);
    if (control && control.value !== type) {
      control.setValue(type === undefined ? null : type);
      this.validateFormAndUpdateWidth();
    }
  }

  validateFormAndUpdateWidth() {
    const typeValue: string = this.form.getControl(productTypeVar).value;
    const defaultWidthType = this.isDefaultWidthType();
    const widthControl = this.form.getControl(widthVar);
    
    if (defaultWidthType) {
      widthControl.setValue(productTypes[typeValue].width);
      widthControl.disable();
    } else {
      widthControl.enable();
    }
    this.formIsInvalid = this.form.invalid;
  }

  async amountApproved(approved: boolean) {
    if (approved) {
      await this.finalizeOrder();
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  private async finalizeOrder() {
    const type: string = this.form.getControl(productTypeVar).value;
    const defaultWidthType: boolean = this.isDefaultWidthType();
    const cartOptions: ISlacksDrawerOptions = {
      type,
      [OptionProperty.typeOfWood]: this.form.getControl(woodTypeVar).value,
      [OptionProperty.surfaceTreatment]: productTypes[type].treatment,
      [OptionProperty.width]: (defaultWidthType ? productTypes[type].width : this.form.getControl(widthVar).value),
      [OptionProperty.depth]: productTypes[type].depth,
      [OptionProperty.height]: productTypes[type].height,
      coupling: (this.form.getControl(couplingVar).value && true)
    };

    const shortType = cartOptions.type + cartOptions[OptionProperty.typeOfWood].substring(0, 2).toUpperCase() + cartOptions.width + (cartOptions.coupling ? 'CO' : '');
    const shortTypeEncoded = btoa(shortType);
    const itemNo: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = this.translate('SlacksDrawer.' + type) + ' '
      + this.translate('TREESORTS.' + cartOptions[OptionProperty.typeOfWood]) + ' '
      + 'W:' + cartOptions.width + 'mm '
      + (defaultWidthType ? this.translate('SlacksDrawer.standardPostfix') : '');

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.slacksDrawer,
      name: this.translate('OTHER_NAMES.SLACKS_DRAWER'),
      itemno: itemNo.varenr,
      description: formattedType,
      amount: this.amount,
      options: cartOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.toastrService.success(this.translate('Cart.AddedToCart'));
    await this.router.navigateByUrl('/');
  }

  private isDefaultWidthType(): boolean {
    const selectedWidthType = this.form.getControl(varWidthVar).value;
    return selectedWidthType === '1'
  }

}
