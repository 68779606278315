import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { StandardSurfaceTreatmentField, StandardThicknessField } from '../../dynamicForm/standard-fields';
import {
  createHeightBaseSelectionRow,
  createHrRow,
  createPremountedCouplingSelectionRow,
  createQualityTypeOfWoodSelectionRow,
  createRunnerDeliverySelectionRow,
  createRunnerWidthSelectionRow,
  createSlideListRow
} from '../../dynamicForm/standard-rows';


export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  {
    id: 'surface-treatment-row',
    show: true,
    fields: [
      StandardSurfaceTreatmentField,
      StandardThicknessField
    ]
  },
  createRunnerWidthSelectionRow(),
  createRunnerDeliverySelectionRow(),
  createHeightBaseSelectionRow(),
  createPremountedCouplingSelectionRow(),
  createSlideListRow(),
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['udsparing', 'logo', 'greb', 'ligeOverkant', 'bundSkruet', 'boerstet']
  }
];
