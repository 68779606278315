<div class="row">
    <div class="col-sm-3">
        <img
            src="/assets/images/drawers/purewood/rev-ezug-special-display.png"
            alt="{{ 'DRAWER.REV_EZUG_SPECIAL.IMAGE_ALT' | translate }}"
            class="img-fluid"
            height="150"
            width="275">
    </div>
    <div class="col-sm-3">
        <div class="mb-3">
            <label class="form-label" for="vt">{{ 'DRAWER.REV_EZUG_SPECIAL.OFFSET_DEPTH' | translate }} (VT)</label>
            <div class="input-group">
                <input
                    [(ngModel)]="measurements.vt"
                    class="form-control"
                    [class.is-invalid]="!validateVT(measurements.vt)"
                    [class.is-valid]="validateVT(measurements.vt)"
                    id="vt"
                    placeholder="VT"
                    step="1"
                    min="40"
                    type="number"
                    onkeydown="return event.keyCode !== 190 && event.keyCode !== 188"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                <span class="input-group-text">mm</span>
            </div>
            <div class="invalid-feedback" *ngIf="!validateVT(measurements.vt)">
                <ng-container *ngIf="getDrawerDepth() != infinityValue; else infiniteDepth">
                    {{ 'DRAWER.REV_EZUG_SPECIAL.VT_VALIDATION' | translate:{min: 40, max: drawerDepth} }}
                </ng-container>
                <ng-template #infiniteDepth>
                    {{ 'DRAWER.REV_EZUG_SPECIAL.VT_VALIDATION_NO_MAX' | translate:{min: 40} }}
                </ng-template>
            </div>
        </div>
        <div class="mb-3">
            <label class="form-label" for="hr">{{ 'DRAWER.REV_EZUG_SPECIAL.BACK_HEIGHT' | translate }} (HR)</label>
            <div class="input-group">
                <input
                    [(ngModel)]="measurements.hr"
                    class="form-control"
                    [class.is-invalid]="!validateHR(measurements.hr)"
                    [class.is-valid]="validateHR(measurements.hr)"
                    id="hr"
                    min="45"
                    step="1"
                    placeholder="HR"
                    type="number"
                    onkeydown="return event.keyCode !== 190 && event.keyCode !== 188"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                <span class="input-group-text">mm</span>
            </div>
            <div class="invalid-feedback" *ngIf="!validateHR(measurements.hr)">
                {{ 'DRAWER.REV_EZUG_SPECIAL.HR_VALIDATION' | translate:{min: 45} }}
            </div>
        </div>
    </div>
</div>
