import { Injectable } from '@angular/core';

import { Constants } from '../../../../wdcommon/Constants';
import {
  CartItemType,
  CartSubType,
  IAddCartItem,
  ICareSetOptions,
  ICutleryTrayOptions,
  ILogoOptions,
  IOtherItemOptions,
  IProfileOptions,
  IPurewoodFrontOptions,
  IPurewoodLinoleumFrontOptions,
  IPurewoodPaintedFrontOptions,
  IPurewoodWoodenFrontOptions,
  ISlacksDrawerOptions,
  ITypedAddCartItem
} from '../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../wdcommon/ICompany';
import { IDrawerOptions, SlideListOptionValue } from '../../../../wdcommon/IDrawer';
import {
  IAddOnPrices,
  IBasePrices,
  IBreakageFee,
  IDrawerPostalInterval,
  IFreight,
  IFreightCalculationCartItems,
  IFrontExtrasPrices,
  IOtherPrices,
  IPostalFreight,
  IPrices,
  ITypePrices,
  PriceType
} from '../../../../wdcommon/IPrices';
import { FrontCategory, IProductCategoryTreeResponse, Manufacturer, ManufacturerWithSuffix, OptionProperty, OptionType, OtherProductShortName } from '../../../../wdcommon/IProduct';
import { APIService, HettichService, LocaleService, SettingService } from './';
import { AdditionalCartItem } from './CartItems';

interface BasePriceIndexes {
  depthClass: string;
  heightClass: string;
  widthIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class PriceCalculatorService {

  constructor(
    private apiService: APIService,
    private hettichService: HettichService,
    private localeService: LocaleService,
    private settingService: SettingService,
  ) {
  }

  private async fetchPrices(
    drawerOptions: IDrawerOptions,
    isErnstMair: boolean,
    isPurewood: boolean,
    isNothegger: boolean,
  ): Promise<IPrices> {
    if (isErnstMair) {
      return drawerOptions.isUpPrice
        ? await this.apiService.getErnstMairPricesUP()
        : await this.apiService.getErnstMairPrices();
    } else if (isPurewood) {
      return await this.apiService.getPurewoodDrawerPrices();
    } else {
      return await this.apiService.getPrices();
    }
  }

  async Calculate(drawerOptions: IDrawerOptions, companyAdjustments?: ICompanyAdjustments): Promise<PriceType> {
    const isErnstMair = (drawerOptions.type.indexOf(Manufacturer.ernstMair) > -1);
    const isPurewood = (drawerOptions.type.indexOf(Manufacturer.purewood) > -1);
    const isNothegger = !isErnstMair && !isPurewood;
    // Company specific price adjustment
    let priceAdjustment = 0;
    if (isErnstMair && companyAdjustments && typeof companyAdjustments[Manufacturer.ernstMair] === 'number') {
      priceAdjustment = companyAdjustments[Manufacturer.ernstMair] ?? 0;
    } else if (isPurewood && companyAdjustments && typeof companyAdjustments[ManufacturerWithSuffix.PurewoodDrawer] === 'number') {
      priceAdjustment = companyAdjustments[ManufacturerWithSuffix.PurewoodDrawer] ?? 0;
    } else if (isNothegger) { // Nothegger
      priceAdjustment = companyAdjustments[Manufacturer.nothegger] ?? Constants.notheggerDefaultAdjustment;
    }

    priceAdjustment = (100 + priceAdjustment) / 100;

    const prices = await this.fetchPrices(drawerOptions, isErnstMair, isPurewood, isNothegger);

    const basePrice: IBasePrices = prices.base;
    const addOnPrices = prices.tillaeg;
    const price: PriceType = {} as PriceType;

    const width = drawerOptions.skuffeBredde;
    const depth = drawerOptions.skuffeDybde;
    const height = drawerOptions.skuffeHoejde;

    // Get base price
    const { depthClass, widthIndex, heightClass } = isErnstMair
      ? this.getErnstMairPriceIndexes(width, height, depth)
      : isPurewood
        ? this.getPurewoodPriceIndexes(width, height, depth)
        : this.getNotheggerPriceIndexes(width, height, depth, drawerOptions[OptionProperty.joint]);

    if (!depthClass || !heightClass || typeof widthIndex === 'undefined') {
      console.error('Error in base price index', depthClass, heightClass, widthIndex);
      return;
    }

    price.base = basePrice[depthClass][heightClass][widthIndex];
    price.baseDG = price.base * priceAdjustment;

    // We calculate add ons
    Object.entries(drawerOptions).forEach(([drawerOptionKey, drawerOptionValue]) => {
      if (drawerOptionKey === 'type') {
        price.type = parseFloat(addOnPrices.type[drawerOptionValue] + '');
        if (['rev_ezug', 'ernst-mair-rev-ezug', 'purewood-rev-ezug'].includes(drawerOptionValue)) {
          price['reverseEnglisherZug'] = 0;
        } else if (drawerOptionValue == 'ernst-mair-rear-ezug') {
          price['rearEnglisherZug'] = 0;
        } else if (["rev_ezug_special", "purewood-rev-ezug_special"].includes(drawerOptionValue)) {
          price['reverseEnglisherZugSpecial'] = 0;
        }
      }

      // Standard add on?
      let value = drawerOptionValue;
      if (value === undefined) {
        return;
      }
      if (drawerOptionKey in addOnPrices) {
        // Special case for wood quality where we have to check whether the quality and type of wood combo is legal
        if (drawerOptionKey === OptionProperty.woodQuality && value === OptionType.cabinet) {
          if (drawerOptions[OptionProperty.typeOfWood].indexOf('noed') > -1) {
            value = 'cab_noed';
          } else if (drawerOptions[OptionProperty.typeOfWood].indexOf('eg') > -1 || drawerOptions[OptionProperty.typeOfWood].indexOf('boeg') > -1) {
            value = 'cab_boeg_eg';
          } else {
            value = 'cab';
          }
        } else if (drawerOptionKey === OptionProperty.premountedCoupling && drawerOptionValue == true) {
          value = drawerOptions[OptionProperty.runnerMark];
        }

        if (drawerOptionKey === 'logo') {
          const logo = value as ILogoOptions;
          value = logo.placement;
        }

        let priceForOption: number;
        if (typeof addOnPrices[drawerOptionKey][value] === 'string' && addOnPrices[drawerOptionKey][value].indexOf('%') > -1) {
          priceForOption = this.calculatePercentage(addOnPrices[drawerOptionKey][value], price.base);
        } else {
          priceForOption = addOnPrices[drawerOptionKey][value];
        }

        if (drawerOptionKey === 'bestikindsats') {
          priceForOption = addOnPrices.bestikindsats[value.type + '_' + value.height];
          // bs tillaeg

          // Type of wood and surface treatment
          const typeOfWoodAddition = this.calculatePercentage(addOnPrices[OptionProperty.typeOfWood][value[OptionProperty.typeOfWood]], priceForOption);
          const surfaceAddition = this.calculatePercentage(addOnPrices[OptionProperty.surfaceTreatment][value[OptionProperty.surfaceTreatment]] + '', priceForOption);

          if (typeOfWoodAddition) {
            priceForOption += typeOfWoodAddition;
          }

          if (surfaceAddition) {
            priceForOption += surfaceAddition;
          }

          if (value.special) {
            priceForOption += parseFloat(addOnPrices.bs_addon['special'] + '');
          }
          if (drawerOptionValue['bundLeveret'] && addOnPrices.extras['bundLeveret']) {
            priceForOption += parseFloat(addOnPrices.extras['bundLeveret'] + '');
          }
        }

        if (drawerOptionKey === 'type' && drawerOptionValue.indexOf('curve') > -1) {
          const innenladeTillaeg = parseFloat(addOnPrices['type']['innenlade'] + '');
          priceForOption += innenladeTillaeg;
        }

        const isReverseEzug = ['rev_ezug', 'purewood-rev-ezug', 'rev_ezug_special', 'purewood-rev-ezug_special'].includes(drawerOptions.type);
        if (drawerOptionKey === 'type' && isReverseEzug) {
          // For Reverse Englisher Zug, the Type should be 70% fee on the base price.
          // For Reverse Englisher Zug, an extra line with the additional innenlade price should be added.
          price.innenlade = priceForOption * priceAdjustment;
          priceForOption = price.base * 0.7;
        }

        if (typeof priceForOption === 'number') {
          price[drawerOptionKey] = isErnstMair && [OptionProperty.drawerBase, 'koblinger', OptionProperty.premountedCoupling].indexOf(drawerOptionKey) > -1 ? priceForOption : priceForOption * priceAdjustment;
        }
      } else if (drawerOptionKey in addOnPrices.extras) {
        let priceForOption: any;
        if (drawerOptionKey === 'udsparing') {
          if (['ernst-mair-rev-ezug', 'ernst-mair-rear-ezug'].includes(drawerOptions['type'])) {
            priceForOption = addOnPrices.extras['special'];
          } else {
            priceForOption = addOnPrices.extras['udsparing'];
          }
          if (!isErnstMair && (value.bc !== 150 || value.cd !== 200)) {
            priceForOption += this.calculatePercentage(addOnPrices.extras['udsparing_custom'] + '', price.base);
          }
        } else {
          priceForOption = addOnPrices.extras[drawerOptionKey];

          if (typeof (priceForOption) === 'object') {
            priceForOption = priceForOption[value];
          }
        }

        if (typeof priceForOption === 'string' && priceForOption.indexOf('%') > -1) {
          priceForOption = this.calculatePercentage(priceForOption, price.base);
        }
        if (priceForOption !== null && priceForOption !== false) {
          if ([OptionProperty.fscCertified, 'straightLine'].indexOf(drawerOptionKey) > -1 || isErnstMair) {
            price[drawerOptionKey] = priceForOption;
          } else if (drawerOptionKey === OptionProperty.slideList && value === SlideListOptionValue.slideNo) {
            price[drawerOptionKey] = 0;
          } else {
            price[drawerOptionKey] = priceForOption * priceAdjustment;
          }
        }
      }
    });

    // Total price is the price shown in the cart
    delete price.base;
    let totalPrice = 0;
    Object.keys(price).forEach(p => {
      if (isNaN(price[p])) {
        console.error(`Price for '${p}' was NaN`, {
          priceType: p,
          extraPrices: addOnPrices,
          isErnstMair,
          isPurewood,
          isNothegger,
          priceAdjustment
        })
        return;
      }
      if (typeof price[p] !== 'number') return;

      totalPrice += price[p];
    });
    price.total = totalPrice;

    return price;
  }

  async CalculateRunner(productNumber: string, companyAdjustments: ICompanyAdjustments): Promise<number> {
    const hettichBrandId = await this.hettichService.getHettichBrandId();
    const priceAdjustment = (100 + (companyAdjustments[hettichBrandId] ?? 0)) / 100;
    if (!productNumber) {
      throw new Error('UDTR NO VARENR! GOT: ' + productNumber);
    }
    const prices: ITypePrices = await this.apiService.getRunnerPrices();
    let price: number;
    if (productNumber.indexOf('koblinger') > -1) {
      const couplingKey = productNumber.split('-')[1];
      price = (prices['koblinger'] || prices)[couplingKey];
    } else {
      price = prices[productNumber];
    }

    if (!price) {
      price = 0;
    }
    return price * priceAdjustment;
  }

  calculatePercentage(percentage: string | undefined, basePrice: number) {
    if (!percentage) {
      return 0;
    }
    const val = parseFloat(percentage.split('%')[0]) / 100;
    return basePrice * val;
  }

  async CalculateOthers(item: ITypedAddCartItem, companyAdjustments?: ICompanyAdjustments): Promise<number> {
    let priceAdjustment = 1;
    if (companyAdjustments && (!item.description || item.description.toLowerCase().indexOf('hettich') === -1) && item.itemno !== '9257706') { // 9257706 = Depth-adjustment for Actro 5D
      priceAdjustment = (100 + (companyAdjustments[item.brandId] ?? 0)) / 100;
    }

    const otherPrices: IOtherPrices = await this.apiService.getOtherPrices();
    const allPrices: IPrices = await this.apiService.getPrices();
    const addOnPrices: IAddOnPrices = allPrices.tillaeg;

    let type: string | CartItemType;
    if (item.itemno && item.itemno.indexOf('SF') > -1) {
      type = 'fastLine';
    } else if (item.options) {
      type = item.options.type;
    } else if (item.type) {
      type = item.type;
    } else {
      type = '';
    }

    let price: number;
    if (item.name && item.subType === CartSubType.afstandsliste) {
      type = OptionProperty.shimsSelection;
      price = otherPrices[type]['base'];
    } else if (item.name && item.subType === CartSubType.bestikskuffe) {
      const options = item.options as ICutleryTrayOptions;
      const bsKey = options.type + '_' + options.height;
      const basePrice = addOnPrices.bestikindsats[bsKey];
      const woodAdjust = this.calculatePercentage(addOnPrices.bs_addon[options[OptionProperty.typeOfWood]] + '', basePrice);
      const treatmentAdjust = this.calculatePercentage(addOnPrices.bs_addon[options[OptionProperty.surfaceTreatment]] + '', basePrice);
      const specialAddOn = options.special ? parseFloat(addOnPrices.bs_addon['special'] + '') : 0;
      price = (basePrice + woodAdjust + treatmentAdjust + specialAddOn) * priceAdjustment;
    } else if (item.name && item.subType === CartSubType.sortissimoFastline) {
      const options = item.options as IOtherItemOptions;
      type = options.type;
      const typeCategory = (options[OptionProperty.typeOfWood] === 'boeg' ? 'boeg' : ['am_noed', 'roeget_eg'].includes(options[OptionProperty.typeOfWood]) ? 'luxury' : 'other');
      const sfPrices = addOnPrices.sortissimoFastline;
      const org_price = parseFloat(sfPrices[type][typeCategory] + '');
      const treatmentAdjust = this.calculatePercentage(sfPrices['treatmentAdjust'][options[OptionProperty.surfaceTreatment]] + '', org_price);
      price = (org_price + treatmentAdjust) * priceAdjustment;

    } else if (item.name && item.subType === CartSubType.solidOrganiser) {
      const options = item.options as IOtherItemOptions;
      const solidPrices = addOnPrices[OtherProductShortName.solidOrganiser];
      const org_price = solidPrices['prices'][type];
      const woodAdjust = this.calculatePercentage(solidPrices.woodAdjust[options[OptionProperty.typeOfWood]], org_price);
      const treatmentAdjust = this.calculatePercentage(solidPrices.treatmentAdjust[options[OptionProperty.surfaceTreatment]], org_price);
      price = (org_price + woodAdjust + treatmentAdjust) * priceAdjustment;

    } else if (item.name && item.subType === CartSubType.trashcanDrawer) {
      const options = item.options as IOtherItemOptions;
      price = addOnPrices.trashcanDrawer[type][options[OptionProperty.typeOfWood]] * priceAdjustment;

    } else if (item.name && item.subType === CartSubType.slacksDrawer) {
      const options = item.options as ISlacksDrawerOptions;
      let widthPriceGroup = 'w' + options.width;
      if (!addOnPrices[OtherProductShortName.slacksDrawer][type][widthPriceGroup]) {
        widthPriceGroup = 'default';
      }
      price = addOnPrices.slacksDrawer[type][widthPriceGroup][options[OptionProperty.typeOfWood]] * priceAdjustment;

    } else if (item.name && item.subType === CartSubType.profil) {
      const options = item.options as IProfileOptions;
      type = OtherProductShortName.profiles;
      price = otherPrices[type][options.height + '-' + options.length];

    } else if (item.subType === CartSubType.careSet) {
      const options = item.options as ICareSetOptions;
      type = OtherProductShortName.careSet;
      price = otherPrices[type][options[OptionProperty.articleNumber]] * priceAdjustment;

    } else if (item.itemno) {
      price = allPrices.udtraek[item.itemno]; // BUG?: Could result in the price being set to undefined.
    }

    if ([CartSubType.profil, CartSubType.afstandsliste, CartSubType.fronts].includes(item.subType)) {
      const typeOfWoodAddition = this.calculatePercentage(addOnPrices[OptionProperty.typeOfWood][item.options[OptionProperty.typeOfWood]], price);
      const surfaceAddition = this.calculatePercentage(addOnPrices[OptionProperty.surfaceTreatment][item.options[OptionProperty.surfaceTreatment]] + '', price);

      price += typeOfWoodAddition;
      price += surfaceAddition;

      if (item.options && item.options.bundLeveret === true) {
        price += parseFloat(addOnPrices.extras['bundLeveret'] + '');
      }

      price = price * priceAdjustment;
    }

    return price;
  }

  async calculateFront(frontItem: IAddCartItem, companyAdjustments: ICompanyAdjustments) {
    const priceAdjustment = (100 + (companyAdjustments[Manufacturer.purewood] ?? 0)) / 100;
    const frontOptions = frontItem.options as IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions | IPurewoodPaintedFrontOptions;
    const m2 = frontOptions[OptionProperty.width] * frontOptions[OptionProperty.height] / (1000 * 1000); // Converting from mm2 to m2

    const priceCategory = frontOptions.category + ([FrontCategory.wooden, FrontCategory.veneer, FrontCategory.linoleum].includes(frontOptions.category) && frontOptions[OptionProperty.typeOfWood] ? '-' + frontOptions[OptionProperty.typeOfWood] : '');
    const prices = await this.apiService.getFrontPrices();

    if (prices[priceCategory][frontOptions.design]) {
      const drillingPrice = await this.calculateFrontDrilling(frontOptions);
      const baseProductPrice = m2 * prices[priceCategory][frontOptions.design] * priceAdjustment;
      const gripPrice = await this.calculateFrontGrip(frontOptions[OptionProperty.frontGrip]);
      const extraPrice = await this.calculateFrontExtras(frontOptions, baseProductPrice, m2);

      return baseProductPrice + drillingPrice + gripPrice + extraPrice;
    }

    return 0;
  }

  private async calculateFrontExtras(frontOptions: IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions | IPurewoodPaintedFrontOptions, basePrice: number, squareMeters: number) {
    const extraPrices = await this.apiService.getFrontExtraPrices();

    let extras: number = 0;
    if (OptionProperty.surfaceTreatment in frontOptions) {
      const surfacePrices = extraPrices?.[OptionProperty.surfaceTreatment];
      const percentageForSurface = surfacePrices?.[frontOptions[OptionProperty.surfaceTreatment]];
      if (percentageForSurface) {
        extras += this.calculatePercentage(percentageForSurface, basePrice);
      }
    }

    // Calculate prices for shaker fronts with glass.
    extras += this.calculateFrontShaker(frontOptions, extraPrices, squareMeters);

    return extras;
  }

  private calculateFrontShaker(frontOptions: IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions | IPurewoodPaintedFrontOptions, extraPrices: IFrontExtrasPrices, squareMeters: number) {
    if (!this.isShakerFront(frontOptions.frontType)) return 0;
    const addonPriceString = extraPrices?.[frontOptions.frontType]?.addon;
    const pricePerM2String = extraPrices?.[frontOptions.frontType]?.perSquareMeter;

    const addonPrice = addonPriceString ? parseFloat(addonPriceString) : 0;
    const pricePerM2 = (pricePerM2String ? parseFloat(pricePerM2String) : 0) * squareMeters;

    console.log('Shaker front', addonPrice, pricePerM2, squareMeters);

    return addonPrice + pricePerM2;
  }

  private isShakerFront(frontType: string) {
    const shakerFronts = ['shaker-60', 'shaker-with-glass'];
    return shakerFronts.includes(frontType);
  }

  private async calculateFrontDrilling(frontOptions: IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions | IPurewoodPaintedFrontOptions) {
    if (!frontOptions[OptionProperty.frontDrillType] || frontOptions[OptionProperty.frontDrillType] === 'none') {
      return 0;
    }

    const drillingSetup = await this.apiService.getFrontDrillingSetup();

    if (frontOptions[OptionProperty.frontDrillType].indexOf('hingesIkea') > -1) {
      for (let option of drillingSetup.hingesIkea) {
        if (frontOptions[OptionProperty.height] >= option.min && frontOptions[OptionProperty.height] <= option.max) {
          return option.hinges * drillingSetup.hingePrice;
        }
      }
    } else if (frontOptions[OptionProperty.frontDrillType].indexOf('hingesUP') > -1) {
      for (let option of drillingSetup.hingesUP) {
        if (frontOptions[OptionProperty.height] >= option.min && frontOptions[OptionProperty.height] <= option.max) {
          return option.hinges * drillingSetup.hingePrice;
        }
      }
    } else if (frontOptions[OptionProperty.frontDrillType] === 'drawerIkeaMetod') {
      return drillingSetup.drawerPrice;
    }

    return 0;
  }

  private async calculateFrontGrip(frontGrip: string) {
    if (!frontGrip || frontGrip === 'none') {
      return 0;
    }

    const drillingSetup = await this.apiService.getFrontDrillingSetup();

    const frontGripTypeLetter = frontGrip[0];
    if (drillingSetup.gripPrices && typeof drillingSetup.gripPrices[frontGripTypeLetter] === 'number') {
      return drillingSetup.gripPrices[frontGripTypeLetter];
    } else {
      console.warn('No price found for grip', frontGrip, drillingSetup.gripPrices);
    }

    return 0;
  }

  CalculateCarcass(item: IAddCartItem): number {
    return (item.amount - item.amount); // = 0!
  }

  async CalculateAdditional(i: AdditionalCartItem, companyAdjustments: ICompanyAdjustments): Promise<number> {
    const priceAdjustment = (100 + (companyAdjustments[i.brandId] ?? 0)) / 100;
    return i.price * priceAdjustment;
  }

  async CalculateFreight(order: IFreightCalculationCartItems, countryCode: string, postalCode: number): Promise<IFreight> {
    const prices: IPrices = await this.apiService.getPrices();

    // Calculate freight from number of drawers
    let numberOfItems = 0;

    order.drawers.forEach((d) => {
      numberOfItems += d.amount;
      if ('bestikindsats' in d.options) {
        numberOfItems += d.amount;
      }
    });

    order.extras.forEach((d) => {
      if ([CartSubType.bestikskuffe, CartSubType.solidOrganiser].includes(d.subType)) {
        numberOfItems += d.amount;
      }
    });

    let postalCodePrices: IPostalFreight = (prices.tillaeg.fragt[countryCode].postnr).find((p) => p.minPostalCode <= postalCode && postalCode <= p.maxPostalCode);
    if (!postalCodePrices) {
      postalCodePrices = prices.tillaeg.fragt[countryCode].default;
    }

    let freightPrice: number;
    if (countryCode === 'no' || countryCode === 'se') {
      freightPrice = numberOfItems * postalCodePrices.fragtPrice;

    } else {
      let freightVal: string;
      if (numberOfItems < 21) {
        freightVal = IDrawerPostalInterval.i01to20;
      } else if (numberOfItems < 41) {
        freightVal = IDrawerPostalInterval.i21to40;
      } else if (numberOfItems < 61) {
        freightVal = IDrawerPostalInterval.i41to60;
      } else if (numberOfItems < 81) {
        freightVal = IDrawerPostalInterval.i61to80;
      } else if (numberOfItems < 101) {
        freightVal = IDrawerPostalInterval.i81to100;
      } else {
        freightVal = IDrawerPostalInterval.i101to;
      }
      freightPrice = numberOfItems * postalCodePrices[freightVal];
    }

    // Check for profiles
    order.extras.every((o) => {
      if (o.subType === CartSubType.profil) {
        freightPrice += postalCodePrices[OtherProductShortName.profiles];
        return;
      }
    });

    if ((order.drawers && order.drawers.length > 0 || order.extras && order.extras.length > 0) &&
      freightPrice < postalCodePrices['min.fragt']) {
      freightPrice = postalCodePrices['min.fragt'];
    }

    let frontsFreight = 0;
    // Check for fronts
    if (Array.isArray(order[CartItemType.fronts]) && order[CartItemType.fronts].length) {
      const freightSetting = await this.settingService.getFreightSetting();
      let m2Small = 0;
      let m2Large = 0;
      order[CartItemType.fronts].forEach((o: ITypedAddCartItem) => {
        const frontOptions = o.options as IPurewoodFrontOptions;
        const m2 = o.amount * frontOptions[OptionProperty.width] / 1000 * frontOptions[OptionProperty.height] / 1000;
        if (frontOptions[OptionProperty.width] < freightSetting.purewood.front.doublePalletLimit && frontOptions[OptionProperty.height] < freightSetting.purewood.front.doublePalletLimit) {
          m2Small += m2;
        } else {
          m2Large += m2;
        }
      });

      if (m2Large === 0 && m2Small * freightSetting.purewood.front.m2ToWeight < freightSetting.purewood.front.palletWeightThreshold) {
        frontsFreight = freightSetting.purewood.front.baseCost[countryCode] + freightSetting.purewood.front.costPerM2[countryCode] * Math.ceil(m2Small);
      } else {
        const minPallets = Math.ceil((m2Large * freightSetting.purewood.front.m2ToWeight) / (freightSetting.purewood.front.palletMaxWeight * 2)) * 2;
        const weightPallets = Math.ceil(((m2Large + m2Small) * freightSetting.purewood.front.m2ToWeight) / freightSetting.purewood.front.palletMaxWeight);
        frontsFreight = Math.max(minPallets, weightPallets) * freightSetting.purewood.front.palletCost[countryCode];
      }
    }

    const brandFreight = Array.from(
      new Map(order.additionals
        .map((additional) => {
          if (!Array.isArray(additional.options.categoryFreightRate)) {
            return null;
          }
          const rates: IProductCategoryTreeResponse[] = additional.options.categoryFreightRate.reverse();
          return rates.find((category) => category.freightRate !== null);
        })
        .filter((categoryFreight) => categoryFreight && categoryFreight.freightRate > 0)
        .map((categoryFreight) => [categoryFreight.id, categoryFreight])
      ).values()
    );

    // Ensure Hettich brand-freight:
    if (order[CartItemType.runner].length > 0) {
      const hettichCategoryId = await this.hettichService.getHettichCategoryId();
      const hit = brandFreight.find((a) => a.id === hettichCategoryId);
      if (hit === undefined) {
        const tree = await this.apiService.getCategoryTree(hettichCategoryId, this.localeService.getLanguage());
        brandFreight.push(tree[0]);
      }
    }

    const breakageFees: IBreakageFee = {
      count: order.additionals
        .filter(additional => additional.breakageFeeIncluded)
        .length,
      price: prices.tillaeg.breakageFee[Constants.breakageFeeItemNumber]
    };

    const customs = (countryCode === 'no') ? postalCodePrices.told : null;

    return {
      basePrice: freightPrice,
      hasFronts: (frontsFreight > 0),
      frontsAddition: frontsFreight,
      additionalsFreight: brandFreight,
      additionalsBreakageFees: breakageFees,
      told: customs
    };
  }

  private getNotheggerPriceIndexes(width: number, height: number, depth: number, joint: string): BasePriceIndexes {
    let depthClass: string;
    if (depth < 203) {
      depthClass = '2';
    } else if (depth <= 303) {
      depthClass = '3';
    } else if (depth <= 403) {
      depthClass = '4';
    } else if (depth <= 503) {
      depthClass = '5';
    } else if (depth <= 603) {
      depthClass = '6';
    } else if (depth <= 703) {
      depthClass = '7';
    } else if (depth <= 803) {
      depthClass = '8';
    } else if (depth <= 903) {
      depthClass = '9';
    } else if (depth <= 1003) {
      depthClass = '10';
    } else if (depth <= 1103) {
      depthClass = '11';
    } else if (depth <= 1203) {
      depthClass = '12';
    }

    let heightClass: string = Number.isInteger(height) ? height + '' : undefined;
    // Swallow jointed - We have to adjust heights
    if (joint === 'svale' || joint === 'fordaekt' || joint === 'svale_fordaekt') {
      switch (height) {
        case 58:
          heightClass = '55';
          break;
        case 84:
          heightClass = '95';
          break;
        case 110:
          heightClass = '115';
          break;
        case 136:
          heightClass = '135';
          break;
        case 162:
          heightClass = '175';
          break;
        case 188:
          heightClass = '195';
          break;
        case 214:
          heightClass = '215';
          break;
        case 240:
          heightClass = '235';
          break;
        case 266:
          heightClass = '275';
          break;
        case 292:
          heightClass = '295';
          break;
      }
    }

    return {
      depthClass: depthClass,
      heightClass: heightClass,
      widthIndex: this.get200to1200WidthIndex(width)
    };
  }

  private getErnstMairPriceIndexes(width: number, height: number, depth: number): BasePriceIndexes {
    let depthClass: string;
    if (depth < 300) {
      depthClass = '2';
    } else if (depth <= 400) {
      depthClass = '3';
    } else if (depth <= 500) {
      depthClass = '4';
    } else if (depth <= 600) {
      depthClass = '5';
    } else if (depth <= 700) {
      depthClass = '6';
    } else if (depth <= 800) {
      depthClass = '7';
    }

    let widthIndex: number;
    if (width < 300) {
      widthIndex = 0;
    } else if (width < 400) {
      widthIndex = 1;
    } else if (width < 500) {
      widthIndex = 2;
    } else if (width < 600) {
      widthIndex = 3;
    } else if (width < 700) {
      widthIndex = 4;
    } else if (width < 800) {
      widthIndex = 5;
    } else if (width < 1000) {
      widthIndex = 6;
    } else if (width < 1200) {
      widthIndex = 7;
    } else {
      console.error('Width out range', width);
    }

    return {
      depthClass: depthClass,
      heightClass: Number.isInteger(height) ? height + '' : undefined,
      widthIndex: widthIndex
    };
  }

  private getPurewoodPriceIndexes(width: number, height: number, depth: number): BasePriceIndexes {
    let depthClass: string;
    if (depth < 205) {
      depthClass = '205';
    } else if (depth <= 305) {
      depthClass = '305';
    } else if (depth <= 405) {
      depthClass = '405';
    } else if (depth <= 505) {
      depthClass = '505';
    } else if (depth <= 605) {
      depthClass = '605';
    } else if (depth <= 705) {
      depthClass = '705';
    } else if (depth <= 805) {
      depthClass = '805';
    } else if (depth <= 905) {
      depthClass = '905';
    } else if (depth <= 1005) {
      depthClass = '1005';
    } else if (depth <= 1105) {
      depthClass = '1105';
    } else if (depth <= 1205) {
      depthClass = '1205';
    }

    return {
      depthClass: depthClass,
      heightClass: Number.isInteger(height) ? height + '' : undefined,
      widthIndex: this.get200to1200WidthIndex(width)
    };
  }

  private get200to1200WidthIndex(width: number): number {
    let widthIndex: number;
    if (width < 200) {
      widthIndex = 0;
    } else if (width < 300) {
      widthIndex = 1;
    } else if (width < 400) {
      widthIndex = 2;
    } else if (width < 500) {
      widthIndex = 3;
    } else if (width < 600) {
      widthIndex = 4;
    } else if (width < 700) {
      widthIndex = 5;
    } else if (width < 800) {
      widthIndex = 6;
    } else if (width < 900) {
      widthIndex = 7;
    } else if (width < 1000) {
      widthIndex = 8;
    } else if (width < 1100) {
      widthIndex = 9;
    } else if (width <= 1200) {
      widthIndex = 10;
    } else {
      console.error('Unsupported width', width);
    }

    return widthIndex;
  }
}
