<div class="container print-full-width">
    <div class="row no-print">

        <app-breadcrumbs [currentBreadcrumb]="cartBreadcrumb"></app-breadcrumbs>

    </div>

    <div *ngIf="cartService.isEditingExistingCart || !canPurchase" class="row no-print">

        <div class="col-sm mt-4">
            <div *ngIf="cartService.isEditingExistingCart" class="alert alert-warning">{{ cartService.translatedEditTitle }}</div>
            <div *ngIf="!canPurchase" class="alert alert-warning" translate="CART.IkkeAktiveretBruger"></div>
        </div>

    </div>

    <app-order-layout-body *ngIf="cart" [items]="cart"
                           [viewMode]="(canPurchase ? IOrderLayoutViewMode.cart : IOrderLayoutViewMode.cart_offline)"></app-order-layout-body>

    <div *ngIf="canPurchase && freightTotal" class="mx-3">
        <div class="row py-2 border-top border-primary">

            <div class="col-sm-5 mt-3 no-print">
                <ng-container *ngIf="oneDeliveryFreight > 0">
                    <ng-container *ngTemplateOutlet="oneDeliveryTemplate"></ng-container>
                </ng-container>
            </div>
            <div class="col-sm-7 print-full-width">
                <div class="row py-2">
                    <div class="col-6 text-end" translate="CART.Varer i alt"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ productsTotal | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="freightTotal.basePrice > 0" class="row">
                    <div class="col-6 text-end" translate="CART.Fragt Træskuffer"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ freightTotal.basePrice | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="freightTotal.hasFronts" class="row">
                    <div class="col-6 text-end" translate="CART.Fragt Fronter"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ freightTotal.frontsAddition | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngFor="let freight of freightTotal.additionalsFreight" class="row">
                    <div class="col-6 text-end">{{ 'CART.freight' | translate }}, {{ freight.name }}</div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ freight.freightRate | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="oneDelivery > 0" class="row">
                    <div class="col-6 text-end" translate="CART.OneDelivery"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ oneDelivery | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="freightTotal.told" class="row">
                    <div class="col-6 text-end" translate="CART.Told"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ freightTotal.told | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngFor="let colour of colours" class="row">
                    <div class="col-6 text-end">{{ 'Purewood.ColourStartup.Fee' | translate }}, {{ colour.colour }}</div>
                    <div class="col-6 text-end fw-bold text-nowrap" *ngIf="freightSetting">
                        {{ freightSetting.purewood.colourStartup.fee[user.company.freightTier] | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="freightTotal.additionalsBreakageFees && freightTotal.additionalsBreakageFees.count" class="row">
                    <div class="col-6 text-end" translate="CART.breakageFee"></div>
                    <div class="col-6 text-end fw-bold text-nowrap">
                        {{ freightTotal.additionalsBreakageFees.count * freightTotal.additionalsBreakageFees.price | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <ng-container *ngIf="user.company.vat">
                    <div class="row py-2">
                        <h4 class="col-9 text-end fw-bold" translate="CART.I alt"></h4>
                        <h4 class="col-3 text-end fw-bold text-nowrap">
                            {{ orderTotalNoVat | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                        </h4>
                    </div>
                </ng-container>
                <ng-container *ngIf="!user.company.vat">
                    <div class="row pt-2">
                        <div class="col-6 text-end" translate="CART.total_ex_vat"></div>
                        <div class="col-6 text-end fw-bold text-nowrap">
                            {{ orderTotalNoVat | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-end" translate="CART.Moms"></div>
                        <div class="col-6 text-end fw-bold text-nowrap">
                            {{ (orderTotalNoVat * 0.25) | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                        </div>
                    </div>
                    <div class="row py-2">
                        <h4 class="col-6 text-end fw-bold">{{ 'CART.total_incl_vat' | translate | uppercase }}</h4>
                        <h4 class="col-6 text-end fw-bold text-nowrap">
                            {{ (orderTotalNoVat * 1.25) | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                        </h4>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
    <div *ngIf="user && user.isAdmin" class="row py-3 border-top border-bottom border-primary no-print">

        <div class="col-sm-3">
            <span class="text-nobreak" translate="CART.e-conomics Kundenr"></span><br>
            <input (blur)="setEcoNr()" [(ngModel)]="ecoNumber" class="form-control" type="number">
        </div>
        <div class="col-sm-3">
            <span>{{ ecoCustomer }}</span>
        </div>

    </div>
    <div class="row no-print">

        <div class="col-sm-3 border-end border-primary py-3">
            <div *ngIf="!altLevering">
                <p><b translate="CART.Leveringsinformationer"></b></p>
                <p *ngIf="stdLevAdr">
                    {{ stdLevAdr.name }}<br> {{ stdLevAdr.address }}<br>
                    <ng-container *ngIf="stdLevAdr.address2">
                        {{ stdLevAdr.address2 }}<br>
                    </ng-container>
                    {{ stdLevAdr.postcode }} {{ stdLevAdr.city }}<br>
                </p>
            </div>
            <ng-container *ngIf="altLevering">
                <div class="mb-1">
                    <label class="form-label" for="input_altLevAdr"><b translate="CART.Leveringsinformationer"></b></label>
                    <input [(ngModel)]="altLevAdr.name" [placeholder]="translate('CART.AltLev.Navn')" class="form-control form-control-sm"
                           id="input_altLevAdr" type="text">
                </div>
                <div class="mb-1">
                    <input [(ngModel)]="altLevAdr.address" [placeholder]="translate('CART.AltLev.Adresse')" class="form-control form-control-sm"
                           type="text">
                </div>
                <div class="mb-1">
                    <div class="col">
                        <input [(ngModel)]="altLevAdr.address2" [placeholder]="translate('CART.AltLev.Adresse') + ' 2'"
                               class="form-control form-control-sm" type="text">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-4">
                        <input (change)="recalculateTotals()" [(ngModel)]="altLevAdr.postcode" [placeholder]="translate('CART.AltLev.Postnr')"
                               appPostalCode class="form-control form-control-sm" min="0" step="1">
                    </div>
                    <div class="col-8">
                        <input [(ngModel)]="altLevAdr.city" [placeholder]="translate('CART.AltLev.By')" class="form-control form-control-sm"
                               type="text">
                    </div>
                </div>
            </ng-container>
            <div class="form-check">
                <input (change)="altLevering = !altLevering" [checked]="altLevering" class="form-check-input" id="altLevCheck" type="checkbox">
                <label class="form-check-label" for="altLevCheck" translate="CART.Alternativ leveringsadresse"></label>
            </div>
        </div>
        <div class="col-sm-3 mb-3 py-3">
            <div>
                <label class="form-label" for="rekv_nr" translate="CART.Rekvisitions nr"></label>
                <input [(ngModel)]="requisition" [placeholder]="translate('CART.Indtast evt. rekvisitions nr')" class="form-control" id="rekv_nr"
                       name="rekv_nr" type="text">
            </div>
            <!-- 
            Disabled by 3256 Fjern ønske leveringsdato på kurv
            <div>
                <label class="form-label" for="lev_dato" translate="CART.Ønsket leveringsdato"></label>
                <input [(ngModel)]="bsDeliveryDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="minDate" bsDatepicker
                       class="form-control" id="lev_dato" name="lev_dato" placeholder="Vælg ønsket leveringsdato" type="text">
                <small class="form-text text-muted" translate="CART.ExpectedDelevery"></small>
            </div> 
            -->
        </div>
        <div class="col-sm-6 py-3">
            <label class="form-label" for="lev_dato" translate="CART.Kommentarer"></label>
            <textarea [(ngModel)]="comments" [placeholder]="translate('CART.EvtKommentarerTilOrdren')" class="form-control" id="kommentar"
                      rows="5"></textarea>
        </div>

    </div>
    <div class="row py-3 border-top border-primary no-print">

        <div class="col-sm-2 text-end">
            <button (click)="modalRef = modalService.show(confirmClearCart)" [disabled]="!canPurchase" class="btn btn-danger form-control">
                <span translate="CART.Slet kurv"></span>&nbsp;
                <fa-icon [icon]="faTrash"></fa-icon>
            </button>
        </div>
        <div class="col-sm-2 text-end">
            <button (click)="modalRef = modalService.show(confirmSaveCart)" [disabled]="!canPurchase" class="btn btn-info form-control">
                <span translate="CART.Gem kurv"></span>&nbsp;
                <fa-icon [icon]="faSave"></fa-icon>
            </button>
        </div>
        <div class="col-sm-2 text-end">
            <button (click)="modalRef = modalService.show(confirmSendOrder); doNotDelete = true" [disabled]="!canPurchase"
                    class="btn btn-primary">
                Debug Godkend
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>
        </div>
        <div class="col-sm text-end">
            <button (click)="modalRef = modalService.show(confirmSendOrder)" *ngIf="!cartService.isEditingExistingCart"
                    [disabled]="!canPurchase" class="btn btn-primary">
                <span translate="CART.Godkend og indsend ordre"></span>&nbsp;
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>

            <button (click)="modalRef = modalService.show(confirmEditOrder)" *ngIf="cartService.isEditingExistingCart"
                    [disabled]="!canPurchase"
                    class="btn btn-primary">
                <span translate="CART.GemRedigeretOrdre"></span>&nbsp;
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>
        </div>

    </div>
    <div *ngIf="user && user.isAdmin" class="row bg-warning mt-4 no-print">

        <div class="col-4 offset-4 my-3">
            <label class="form-label" for="adminNotheggerAdjust">Kun admin: Justering af Nothegger-priser</label>
            <div class="input-group input-group">
                <input (change)="updateNotheggerAdjust()" [(ngModel)]="adminNotheggerAdjust" class="text-end form-control"
                       id="adminNotheggerAdjust" max="100" min="-100" type="number">
                <span class="input-group-text" id="basic-addon2">%</span>
            </div>
            <div class="form-text">OBS: Negative værdier giver rabat. Standardværdi: {{ Constants.notheggerDefaultAdjustment }}</div>
        </div>

    </div>
</div>
<!-- modal templates -->


<ng-template #confirmSendOrder>
    <div *blockUI="'orderBlock'">
        <div class="modal-header">
            <h4 class="modal-title" translate="CART.Godkend og indsend ordre"></h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="!oneDelivery && oneDelivery !== 0">
                <ng-container *ngTemplateOutlet="oneDeliveryTemplate"></ng-container>
            </ng-container>
            <div translate="CART.SikkerGodkendeIndsendeOrdren"></div>
        </div>
        <div class="modal-footer">
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
            <button (click)="confirmAndSendNewOrder()" [disabled]="!oneDelivery && oneDelivery !== 0" class="btn btn-primary"
                    translate="CART.Godkend og indsend" type="button"></button>
        </div>
    </div>
</ng-template>

<ng-template #confirmClearCart>
    <div class="modal-header">
        <h4 class="modal-title" translate="CART.Slet kurv"></h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body"
         translate="CART.SikkerSletKurv"></div>
    <div class="modal-footer">
        <button (click)="confirmAndClearCart()"
                class="btn btn-danger"
                translate="CART.Slet kurv"
                type="button"></button>
        <button (click)="modalRef.hide()"
                class="btn btn-secondary"
                data-dismiss="modal"
                translate="UTILITY.CANCEL"
                type="button"></button>
    </div>
</ng-template>

<ng-template #confirmEditOrder>
    <div *blockUI="'orderBlock'">
        <div class="modal-header">
            <h4 class="modal-title" translate="CART.GemRedigeretOrdre"></h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body"
             translate="CART.SikkerGodkendeIndsendeOrdren"></div>
        <div class="modal-footer">
            <button (click)="confirmAndSendExistingOrder()"
                    class="btn btn-primary"
                    translate="CART.GemRedigeretOrdre"
                    type="button"></button>
            <button (click)="modalRef.hide()"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    translate="UTILITY.CANCEL"
                    type="button"></button>
        </div>
    </div>
</ng-template>

<ng-template #confirmSaveCart>
    <div class="modal-header">
        <h4 class="modal-title"
            translate="CART.Gem kurv"></h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <div class="input group">
                    <label for="saveProjName"
                           translate="CART.Projekt navn"></label>
                    <input [(ngModel)]="saveName"
                           class="form-control"
                           id="saveProjName"
                           type="text">
                </div>
                <div class="form-check">
                    <input [(ngModel)]="clearOnSave"
                           class="form-check-input"
                           id="clearOnSaveCheck"
                           type="checkbox">
                    <label class="form-check-label"
                           for="clearOnSaveCheck"
                           translate="CART.RydNårGemt"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()"
                class="btn btn-secondary"
                data-dismiss="modal"
                translate="UTILITY.CANCEL"
                type="button"></button>
        <button (click)="saveOrder()"
                class="btn btn-success"
                translate="CART.Gem kurv"
                type="button"></button>
    </div>
</ng-template>

<ng-template #oneDeliveryTemplate>
    <div class="alert alert-info m-4 mt-auto p-4">
        <h5 translate="CART.oneDeliveryTitle"></h5>
        <div class="py-3">
            <em [innerText]="'CART.oneDeliveryDescription' | translate"></em>
        </div>
        <div class="form-check">
            <input (click)="oneDelivery = oneDeliveryFreight; recalculateTotals()" [checked]="oneDelivery > 0" class="form-check-input"
                   id="oneDeliveryYes" name="oneDelivery" type="radio">
            <label [translateParams]="{amount: oneDeliveryFreight | number: '1.2-2'}" class="form-check-label" for="oneDeliveryYes"
                   translate="CART.oneDeliveryYes"></label>
        </div>
        <div class="form-check">
            <input (click)="oneDelivery = 0; recalculateTotals()" [checked]="oneDelivery === 0" class="form-check-input" id="oneDeliveryNo"
                   name="oneDelivery" type="radio">
            <label class="form-check-label" for="oneDeliveryNo" translate="CART.oneDeliveryNo"> </label>
        </div>
    </div>
</ng-template>
