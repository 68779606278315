import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { ILogo } from '../../../../../wdcommon/ICompany';
import { getImageMimeFromDataString } from '../../../../../wdcommon/ImageMime';
import { ImageMessageTypes } from '../../../../../wdcommon/IProduct';
import { APIService } from '../../services';


@Component({
  selector: 'app-logo-add',
  templateUrl: './logo-add.component.html',
  styleUrls: ['./logo-add.component.css']
})
export class LogoAddComponent implements OnInit {

  @BlockUI('blockModal') blockUIModal: NgBlockUI;

  public onSelect: Subject<{ id: any, name: string, placement: string; }>;

  placement = 'left';

  logos: ILogo[] = [];

  constructor(
    public modalRef: BsModalRef,
    private api: APIService
  ) {
  }

  async ngOnInit() {
    this.blockUIModal.start('Henter logoer');
    this.onSelect = new Subject();
    this.logos = [];

    const r = await this.api.getCompanyLogos();
    if (r.length === 0) { // No company logo - then we are already ready.
      return this.blockUIModal.stop();
    }

    // for each logo - get base64 image
    const logoPromises: Promise<ILogo>[] = [];
    r.forEach((orgLogo) => {
      const logoCopy: ILogo = JSON.parse(JSON.stringify(orgLogo));
      const logoPromise = this.api.getLogo(logoCopy.id)
        .then((base64Image) => {
          if (base64Image.message === ImageMessageTypes.base64) {
            logoCopy.fileBase64 = 'data:' + getImageMimeFromDataString(base64Image.encImg) + ';base64,' + base64Image.encImg;
          }
          return logoCopy;
        });
      logoPromises.push(logoPromise);
    });
    await Promise.all(logoPromises)
      .then((logos) => {
        this.logos = logos;
      });
    this.blockUIModal.stop();
  }

  closeModal() {
    this.modalRef.hide();
  }

  selectLogo(id, filename) {
    this.onSelect.next({
      id: id,
      name: filename,
      placement: this.placement
    });
    this.modalRef.hide();
  }

}
