<block-ui>

    <div (uploadOutput)="onUploadOutput($event)" [ngClass]="{ 'is-drop-over': dragOver }" [options]="options" [uploadInput]="uploadInput"
         class="drop-container" ngFileDrop>
        <h5><span translate="DragNDrop"></span>&nbsp;<span translate="eller tryk for at vælge"></span></h5>
        <div class="form-file">
            <input (uploadOutput)="onUploadOutput($event)" [options]="options" [uploadInput]="uploadInput" accept="image/png, image/jpg, image/jpeg, application/pdf"
                   class="form-control" id="logoImage" ngFileSelect type="file" value="Vælg fil"/>
            <label class="form-label" for="logoImage">{{'Vælg fil' | translate}}</label>
        </div>
        <div *ngIf="imgUrls && this.imgUrls.length > 0" class="logo-list-image-height m-3">
            <div *ngFor="let img of imgUrls" class="mb-2">
                <img *ngIf="img" [src]="img" alt="" class="NO-CACHE logo-image"/>
            </div>
        </div>
        <button (click)="startUpload()" class="btn btn-primary mt-2" translate="Start Upload" type="button"></button>
    </div>

</block-ui>
