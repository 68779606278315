import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';

import { ILogo } from '../../../../../wdcommon/ICompany';
import { getImageMimeFromDataString } from '../../../../../wdcommon/ImageMime';
import { ImageMessageTypes } from '../../../../../wdcommon/IProduct';
import { IUser } from '../../../../../wdcommon/IUser';
import { SubscribedState, SubscribedStateResponse } from '../../../../../wdcommon/KlaviyoNewsletter';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { APIService, KlaviyoService, LocaleService, UserService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent extends TranslatingBase implements OnInit {
  @BlockUI('pwChangeBlock') pwBlock: NgBlockUI;
  @ViewChild('pop') pop: PopoverDirective;

  faTrash = faTrash;

  myProfileBreadcrumb = defaultBreadcrumbs['myProfile'];

  user: IUser;
  logos: ILogo[];

  preferredLanguage = 'da';

  changePWForm = new UntypedFormGroup({
    oldPW: new UntypedFormControl('', [Validators.required]),
    newPW: new UntypedFormControl('', [Validators.required]),
    newPWRepeat: new UntypedFormControl('', [Validators.required])
  });

  newsletterStatus: SubscribedStateResponse;
  newsletterSubscribing = false;
  SubscribedState = SubscribedState;

  currentPage = 'contactInfo';
  modalRef: BsModalRef;
  delLogoId;

  constructor(
    private localeService: LocaleService,
    private userService: UserService,
    public modalService: BsModalService,
    private toastrService: ToastrService,
    private api: APIService,
    private klaviyo: KlaviyoService,
    private router: Router,
    private translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.user = this.userService.getUser();

    this.klaviyo.getSubscribedState()
      .then((status) => {
        this.newsletterStatus = status;
      });

    this.getLogosFromServer();

    this.preferredLanguage = this.localeService.getLanguage();
  }

  setLanguage() {
    console.log('setting lang to', this.preferredLanguage);
    this.localeService.setLanguage(this.preferredLanguage);
    this.translateService.use(this.preferredLanguage);
  }

  async changePW() {
    console.log(this.changePWForm);
    if (this.changePWForm.value.newPW === this.changePWForm.value.newPWRepeat) {
      this.pwBlock.start();
      this.api.requestPasswordChange(this.changePWForm.value.oldPW, this.changePWForm.value.newPW).subscribe((r: any) => {
        console.log(r);

        if (r.message === 'NO_USER_FOUND') {
          this.toastrService.error('Brugeren kunne ikke findes - kontakt support');
        } else if (r.message === 'PASSWORD_INCORRECT') {
          this.toastrService.error('Det indtastede password er ikke korrekt');
        } else if (r.message === 'PASSWORD_CHANGED') {
          this.modalRef.hide();
          this.router.navigateByUrl('/logout');
          this.toastrService.success('Password er skiftet');
        }
        this.pwBlock.stop();

      });
    } else {
      this.toastrService.error('De to indtastede passwords matcher ikke');
    }
  }

  onUploaded() {
    this.getLogosFromServer();
  }

  async getLogosFromServer() {
    this.logos = [];
    try {
      const logos = await this.api.getCompanyLogos();
      const logoPromises = logos.map(async (logo) => {
        const logoRes = await this.api.getLogo(logo.id);
        if (logoRes.message === ImageMessageTypes.base64) {
          logo.fileBase64 = 'data:' + getImageMimeFromDataString(logoRes.encImg) + ';base64,' + logoRes.encImg;
        }
        return logo;
      });
      this.logos = await Promise.all(logoPromises);
    } catch (error) {
      console.error('Error fetching logos:', error);
    }
  }

  deleteLogo() {
    this.api.deleteLogo(this.delLogoId).subscribe(() => {
      this.logos = [];
      this.getLogosFromServer();
    });
  }

  async subscribeToNewsletter() {
    this.newsletterSubscribing = true;
    await this.klaviyo.subscribe();
  }

  async unsubscribeNewsletter() {
    await this.klaviyo.unsubscribe();
    this.newsletterStatus = { subscribed: SubscribedState.unsubscribed, success: true };
  }

}
