import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFormService, DynamicInputModel } from '@ng-dynamic-forms/core';
import { TranslateService } from '@ngx-translate/core';

import { ISiphonCuttingOptions } from '../../../../../wdcommon/ICartItem';
import { SiphonCuttingValues } from '../../../../../wdcommon/SiphonCutting';
import { TranslatingBase } from '../../base-component/ComponentBase';

const defaultWidth = 700;
const defaultHeight = 500;

// Path2D fix
@Component({
  selector: 'app-siphon-cutting',
  templateUrl: './siphon-cutting.component.html',
  styleUrls: ['./siphon-cutting.component.css']
})
export class SiphonCuttingComponent extends TranslatingBase implements OnInit {

  @Input() defaults: SiphonCuttingValues;

  @ViewChild('siphonCuttingCanvas') canvasRef: ElementRef;
  @ViewChild('siphonCuttingCanvasParent') canvasParentRef: ElementRef;

  canvasHeight = defaultHeight;
  canvasWidth = defaultWidth; // In order for this to be based on window size, then do not set this value here. However, that leads to different sized images being set on order.
  // resizeCanvas = false;

  minAbEf = 10;

  maxWidthCoordinate = 0;

  measuresForm: DynamicInputModel[] = [
    new DynamicInputModel({
      id: 'ab',
      min: 10,
      label: 'AB',
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'bc',
      label: 'BC',
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'cd',
      label: 'CD',
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'ef',
      min: 10,
      label: 'EF',
      inputType: 'number'
    })
  ];

  formGroup: UntypedFormGroup;
  rootValues: SiphonCuttingValues;
  outOfBounds = false;

  constructor(
      private formService: DynamicFormService,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.formGroup = this.formService.createFormGroup(this.measuresForm);
  }

  // Use this to set width based on window size.
  // ngAfterContentChecked() {
  //   if (!this.resizeCanvas) {
  //     return;
  //   }
  //
  //   const offset = 16;
  //   if (this.canvasWidth === (this.canvasParentRef.nativeElement.offsetWidth - offset)) {
  //     this.resizeCanvas = false;
  //     this.siphonCuttingInit();
  //     return;
  //   }
  //
  //   // this.canvasHeight = this.canvasParentRef.nativeElement.offsetHeight;
  //   this.canvasWidth = this.canvasParentRef.nativeElement.offsetWidth - offset;
  // }

  siphonCuttingInit() { // Param: reset = false
    // if (reset) {
    //   this.resizeCanvas = true;
    // }
    if (!this.defaults.existing) {
      if (this.defaults.width < 220) {
        // Calculate CD
        this.defaults.cd = this.defaults.width - 42;
      } else {
        this.defaults.cd = 200;
      }

      // Calculate AB and EF
      const abEfAvg = (this.defaults.width - this.defaults.cd) / 2;
      this.defaults.ab = Math.ceil(abEfAvg);
      this.defaults.ef = Math.floor(abEfAvg);
    }
    this.measuresForm.forEach((ctrl) => {
      ctrl.value = this.defaults[ctrl.id];
    });

    this.rootValues = this.defaults;

    this.drawSiphonCutting();
  }

  changeHandler(event) {
    // Check that the siphon cutting exists within the frames (AB-EF min.)
    if (event.model.id === 'ab' || event.model.id === 'ef') {
      if (event.control.value < this.minAbEf) {
        (event.model as DynamicInputModel).value = this.minAbEf;
      }
    }
    const abCtrl = this.measuresForm.filter((e) => {
      return e.id === 'ab';
    })[0] as DynamicInputModel;
    const efCtrl = this.measuresForm.filter((e) => {
      return e.id === 'ef';
    })[0] as DynamicInputModel;
    const cdCtrl = this.measuresForm.filter((e) => {
      return e.id === 'cd';
    })[0] as DynamicInputModel;

    let ab, ef;
    // Calculate ab, ef and cd
    if (event.model.id === 'cd') {
      const base = this.defaults.width - event.control.value;
      ab = Math.ceil(base / 2);
      ef = Math.floor(base / 2);

      if (ab >= 10 && ef >= 10) {
        abCtrl.value = ab;
        efCtrl.value = ef;
      } else {
        // Max C-D value
        (event.model as DynamicInputModel).value = this.defaults.width - (this.minAbEf * 2);
      }
    }

    if (event.model.id === 'ab') {
      // New E-F value
      efCtrl.value = this.defaults.width - event.control.value - parseInt(cdCtrl.value + '', 10);
    }

    if (event.model.id === 'ef') {
      // New A-B value
      abCtrl.value = this.defaults.width - event.control.value - parseInt(cdCtrl.value + '', 10);
    }

    this.measuresForm.forEach((ctrl) => {
      this.rootValues[ctrl.id] = parseInt(ctrl.value + '', 10);
    });

    this.drawSiphonCutting();
  }

  getSiphonCutting(): ISiphonCuttingOptions {
    // if (this.resizeCanvas) {
    //   return;
    // }
    const canvasElm: HTMLCanvasElement = this.canvasRef.nativeElement;

    const croppedCanvas = SiphonCuttingComponent.cropCanvas(canvasElm, 0, 0, this.canvasWidth, this.canvasHeight);
    return {
      ab: this.rootValues.ab,
      bc: this.rootValues.bc,
      cd: this.rootValues.cd,
      ef: this.rootValues.ef,
      image: croppedCanvas.toDataURL(),
      special: this.outOfBounds
    };
  }

  private drawSiphonCutting() {
    // if (!this.canvasWidth || !this.canvasHeight || this.resizeCanvas) {
    //   this.resizeCanvas = true;
    //   return;
    // }
    this.outOfBounds = (this.rootValues.cd !== 200 || this.rootValues.bc !== 150);

    const ctx: CanvasRenderingContext2D = this.canvasRef.nativeElement.getContext('2d');
    // Calculate siphon cutting
    /* Drawer dimension
    A - B     E - F
        |
        C --- D
    */

    // Get values from controls
    const values = this.rootValues;

    // Calculate valid values
    const offsetX = 20;
    const offsetY = 30;
    const factor = Math.min((this.canvasWidth - (offsetX * 2)) / this.defaults.width, (this.canvasHeight - (offsetY * 2)) / this.defaults.depth);
    const thickness = 8 * factor;

    ctx.lineWidth = 2;
    const outerPath = new Path2D('M' + offsetX + ' ' + offsetY +
        ' h ' + (values.ab * factor) +
        ' v ' + (values.bc * factor) +
        ' h ' + (values.cd * factor) +
        ' v -' + (values.bc * factor) +
        ' h ' + (values.ef * factor) +
        ' v ' + (this.defaults.depth * factor) +
        ' h -' + (this.defaults.width * factor) +
        ' v -' + (this.defaults.depth * factor) +
        ' Z ');

    const innerPath = new Path2D('M' + (offsetX + thickness) + ' ' + (offsetY + thickness) +
        ' h ' + ((values.ab * factor) - (thickness * 2)) +
        ' v ' + ((values.bc * factor) - (thickness / 16)) +
        ' h ' + ((values.cd * factor) + (thickness * 2)) +
        ' v -' + ((values.bc * factor) + (thickness / 16)) +
        ' h ' + ((values.ef * factor) - (thickness * 2)) +
        ' v ' + ((this.defaults.depth * factor) - (thickness * 2)) +
        ' h -' + ((this.defaults.width * factor) - (thickness * 2)) +
        ' v -' + ((this.defaults.depth * factor) - (thickness * 2))
    );

    this.maxWidthCoordinate = (((values.ab + values.cd + values.ef) * factor) + offsetX + 9) + 10;

    // Clear canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    ctx.fillStyle = 'black';

    ctx.fillStyle = '#ebebeb';
    ctx.fill(outerPath);
    ctx.fillStyle = '#c0c0c0';
    ctx.fill(innerPath);
    ctx.strokeStyle = '#939393';
    ctx.stroke(outerPath);
    ctx.stroke(innerPath);

    // Add text to drawing
    ctx.fillStyle = 'black';
    ctx.font = '18px Arial';
    ctx.textBaseline = 'alphabetic';
    ctx.fillText('A', 0, offsetY - 9);
    ctx.fillText('B', (values.ab * factor) + offsetX + 9, offsetY - 9);
    ctx.fillText('C', (values.ab * factor) + offsetX + 9, (values.bc * factor) + 18);
    ctx.fillText('D', ((values.ab + values.cd) * factor), (values.bc * factor) + 18);
    ctx.fillText('E', ((values.ab + values.cd) * factor), offsetY - 9);
    ctx.fillText('F', ((values.ab + values.cd + values.ef) * factor) + offsetX + 9, offsetY - 9);

    // Text for measures
    ctx.font = '13px Arial';


    const lineOffset = 12;
    const textOffset = 18;

    // 'M' + offsetX + ' ' + offsetY +
    // ' h ' + (values.ab * factor) +

    // ' h ' + (values.cd * factor) +

    const abPos = {
      x: ((values.ab * factor) + offsetX + 18) / 2,
      y: offsetY - lineOffset
    };

    const cdPos = {
      x: ((values.ab * factor) + 9) + ((values.cd * factor) / 2),
      y: (values.bc * factor) + 12
    };

    const efPos = {
      x: ((values.ab * factor) + (values.cd * factor) + 12) + ((values.ef * factor) / 2),
      y: offsetY - lineOffset
    };

    // Lines
    ctx.beginPath();
    ctx.moveTo(textOffset + 1, offsetY - lineOffset);
    ctx.lineTo(values.ab * factor + textOffset + 3, offsetY - lineOffset);
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';
    ctx.fillText(values.ab + '', abPos.x, abPos.y);

    ctx.moveTo((values.ab * factor) + offsetX + 9 + 3, offsetY);
    ctx.lineTo((values.ab * factor) + offsetX + 9 + 3, (values.bc * factor));
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'left';
    ctx.fillText(values.bc + '', (values.ab * factor + textOffset + 18), (values.bc * factor + 27) / 2);

    ctx.moveTo((values.ab * factor) + offsetX + 9 + 18, (values.bc * factor) + 15);
    ctx.lineTo(((values.ab + values.cd) * factor) - 5, (values.bc * factor) + 15);
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';
    ctx.fillText(values.cd + '', cdPos.x, cdPos.y);

    ctx.moveTo(((values.ab + values.cd) * factor) + 18 + 1, offsetY - lineOffset);
    ctx.lineTo(((values.ab + values.cd + values.ef) * factor) + offsetX, offsetY - lineOffset);
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'left';
    ctx.fillText(values.ef + '', efPos.x, efPos.y);

    ctx.closePath();
    ctx.stroke();
  }

  private static cropCanvas(sourceCanvas, left, top, width, height) {
    width = width || defaultWidth;
    height = height || defaultHeight;
    console.debug('Creating siphon cutting', left, top, width, height);
    const destCanvas = document.createElement('canvas');
    destCanvas.width = width;
    destCanvas.height = height;
    destCanvas.getContext('2d')
        .drawImage(sourceCanvas,
            left, top, width, height,  // source rect with content to crop
            0, 0, width, height // newCanvas, same size as source rect
        );
    return destCanvas;
  }

}
