import { Validators } from '@angular/forms';

import { OptionProperty } from '../../../../wdcommon/IProduct';
import { FieldConfig } from './dynaform.interfaces';

export const StandardCouplingField: FieldConfig = {
  type: 'select',
  classes: 'col-sm-6',
  name: OptionProperty.premountedCoupling,
  label: 'Formonteret Kobling',
  disabled: true,
  options: [],
  validations: [
    {
      name: 'required',
      validator: Validators.required,
      message: 'Der skal vælges kobling'
    }
  ]
};

export const StandardDrawerBaseField: FieldConfig = {
  type: 'select',
  name: OptionProperty.drawerBase,
  label: 'Bund',
  disabled: true,
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges bund'
  }]
};

export const StandardIncludeCouplingField: FieldConfig = {
  type: 'select',
  classes: 'col-sm-6',
  name: OptionProperty.couplingAlongside,
  label: 'Tilkøb af kobling',
  helptext: 'Koblinger kan leveres sammen med skuffen, uden formontering',
  disabled: true,
  show: false,
  options: [
    {
      label: 'Nej',
      value: 'nej'
    },
    {
      label: 'Ja',
      value: 'ja'
    }
  ]
};

export const StandardJointField: FieldConfig = {
  type: 'select',
  name: OptionProperty.joint,
  label: 'Samling',
  classes: 'col-sm-6',
  disabled: true,
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges samling'
  }]
};

export const StandardThicknessField: FieldConfig = {
  type: "select",
  label: "Thickness",
  name: OptionProperty.thickness,
  classes: "col-sm-6",
  options: [],
  validations: [{
    name: "required",
    validator: Validators.required,
    message: "Required.Thickness"
  }]
}

export const StandardRunnerLoadField: FieldConfig = {
  type: 'select',
  name: OptionProperty.runnerLoad,
  label: 'Udtræk vægt',
  suffix: 'kg',
  disabled: true,
  show: false,
  options: [],
  classes: 'col-sm-2',
  translate: { itemLabel: false },
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Udtræk vægt skal udfyldes'
  }],
};

export const StandardRunnerMarkField: FieldConfig = {
  type: 'select',
  name: OptionProperty.runnerMark,
  label: 'Udtræk',
  disabled: true,
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges udtræk'
  }]
};

export const StandardRunnerTypeField: FieldConfig = {
  type: 'select',
  name: OptionProperty.runnerType,
  label: 'Udtræk type',
  disabled: true,
  show: false,
  options: [],
  classes: 'col-sm-2',
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges udtræks type'
  }]
};

export const StandardSurfaceTreatmentField: FieldConfig = {
  type: 'select',
  name: OptionProperty.surfaceTreatment,
  label: 'Overflade',
  classes: 'col-sm-6',
  disabled: true,
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges overflade'
  }],
  translate: {
    helptext: false
  }
};

export const StandardTypeOfWoodField: FieldConfig = {
  type: 'select',
  name: OptionProperty.typeOfWood,
  label: 'Træsort',
  classes: 'col-sm-6',
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges træsort'
  }]
};

export const StandardWoodQualityField: FieldConfig = {
  type: 'select',
  name: OptionProperty.woodQuality,
  label: 'Kvalitet',
  classes: 'col-sm-6',
  options: [],
  validations: [{
    name: 'required',
    validator: Validators.required,
    message: 'Der skal vælges kvalitet'
  }]
};

export const StandardEdgeRoundingField: FieldConfig = {
  type: 'select',
  name: OptionProperty.edgeRounding,
  label: 'fronts.description.edgeRounding.label',
  classes: 'col-sm-6',
  value: null,
  options: [],
  validations: [
    {
      name: 'required',
      validator: Validators.required,
      message: 'Der skal vælges en kantrunding'
    }
  ]
}

export const StandardShakerBorderWidthField: FieldConfig = {
  type: 'input',
  inputType: 'number',
  min: 0,
  suffix: "mm",
  placeholder: 'fronts.description.shakerBorderWidth.placeholder',
  name: OptionProperty.shakerBorderWidth,
  label: 'fronts.description.shakerBorderWidth.label',
  classes: 'col-sm-6',
  value: null,
  validations: [
    {
      name: 'required',
      validator: Validators.required,
      message: 'Der skal vælges en rammebredde'
    },
    {
      name: 'min',
      validator: Validators.min(20),
      message: 'Rammebredde skal være større end 20mm'
    }
  ]
}

export const StandardVeneerDirectionField: FieldConfig = {
  type: 'select',
  name: 'veneerDirection',
  label: 'fronts.description.veneerDirection.label',
  classes: 'col-sm-6',
  value: null,
  validations: [
    {
      name: 'required',
      validator: Validators.required,
      message: 'Der skal vælges Retning'
    }
  ],
  options: [
    {
      value: 'vertical',
      label: 'VENEERDIRECTION.vertical'
    },
    {
      value: 'horizontal',
      label: 'VENEERDIRECTION.horizontal'
    }
  ]
}

export enum DrawerHeightFieldName {
  height = 'Højde',
  sideHeight = 'Højde (side højde)',
}

export function createDrawerHeightField(name: DrawerHeightFieldName = DrawerHeightFieldName.height, disabled: boolean = true): FieldConfig {
  return {
    type: 'select',
    name: OptionProperty.height,
    label: name,
    disabled: disabled,
    options: [],
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Der skal vælges højde'
      }
    ],
    translate: {
      itemLabel: false,
      helptext: false,
      fieldLabel: false
    }
  };
}

export enum DrawerWidthFieldName {
  outerWidth = 'Lysmål',
  width = 'Bredde'
}

export function createDrawerWidthField(min: number = 135, max: number = 1200, name: DrawerWidthFieldName = DrawerWidthFieldName.outerWidth): FieldConfig {
  return {
    type: 'input',
    inputType: 'number',
    name: OptionProperty.drawerOuterWidth,
    min: min,
    max: max,
    suffix: 'mm',
    label: name,
    placeholder: 'Indtast ' + name.toLowerCase(),
    disabled: true,
    classes: 'col-sm-3',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: name + ' skal udfyldes'
      },
      {
        name: 'min',
        validator: Validators.min(min),
        message: name + ' minimum er 135 mm'
      },
      {
        name: 'max',
        validator: Validators.max(max),
        message: name + ' maximum er 1200 mm'
      },
      {
        name: 'pattern',
        validator: Validators.pattern('\\d*'),
        message: name + ' skal indtastes som et heltal'
      }
    ],
    translate: { helptext: false }
  };
}

export enum RunnerDepthFieldName {
  runnerDepth = 'Skinnedybde',
  drawerDepth = 'Skuffedybde'
}

export const StandardRunnerDepthSelectField: FieldConfig = {
  type: 'select',
  name: OptionProperty.runnerDepthSelect,
  label: RunnerDepthFieldName.runnerDepth,
  suffix: 'mm',
  validations: [
    {
      name: 'required',
      validator: Validators.required,
      message: 'Skinnedybde skal udfyldes'
    }
  ],
  disabled: true,
  show: false,
  options: [],
  classes: 'col-sm-3',
  translate: { itemLabel: false, helptext: false }
};

export function createRunnerDepthField(min: number = 150, max: number = 1200, name: RunnerDepthFieldName = RunnerDepthFieldName.runnerDepth): FieldConfig {
  return {
    type: 'input',
    inputType: 'number',
    name: OptionProperty.runnerDepth,
    label: name,
    min: min,
    max: max,
    suffix: 'mm',
    placeholder: 'Indtast ' + name.toLowerCase(),
    disabled: true,
    classes: 'col-sm-3',
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: name + ' skal udfyldes'
      }, {
        name: 'pattern',
        validator: Validators.pattern('\\d*'),
        message: name + ' skal indtastes som et heltal'
      }, {
        name: 'min',
        validator: Validators.min(min),
        message: name + ' minimum er ' + min + ' mm'
      }, {
        name: 'max',
        validator: Validators.max(max),
        message: name + ' maximum er ' + max + ' mm'
      }
    ],
    translate: { itemLabel: false, helptext: false }
  };
}
